/* You can add global styles to this file, and also import other style files */
// For more information: https://material.angular.io/guide/theming

@use '@angular/material' as matt;
@use 'styles/global' as g;
@use 'sass:map';
@use 'node_modules/@ngneat/hot-toast/src/styles/styles.scss';
// @import '~bootstrap/dist/css/bootstrap.css';


// typography
$grow-typography-config:
  map.merge(
    matt.define-typography-config(
      $font-family:   'Inter, "Helvetica Neue", sans-serif',
      $headline-1:     matt.define-typography-level(g.pixel-to-vmin(112px), g.pixel-to-vmin(136px), 300, $letter-spacing: g.pixel-to-vmin(-2.5px) ),
      $headline-2:     matt.define-typography-level(g.pixel-to-vmin(72px),  g.pixel-to-vmin(87px),  300, $letter-spacing: g.pixel-to-vmin(-1.61px)),
      $headline-3:     matt.define-typography-level(g.pixel-to-vmin(64px),  g.pixel-to-vmin(77px),  300, $letter-spacing: g.pixel-to-vmin(-1.43px)),
      $headline-4:     matt.define-typography-level(g.pixel-to-vmin(32px),  g.pixel-to-vmin(39px),  300, $letter-spacing: g.pixel-to-vmin(-0.69px)),
      $headline-5:     matt.define-typography-level(g.pixel-to-vmin(56px),  g.pixel-to-vmin(68px),  500, $letter-spacing: g.pixel-to-vmin(-1.25px)),
      $headline-6:     matt.define-typography-level(g.pixel-to-vmin(32px),  g.pixel-to-vmin(41px),  500, $letter-spacing: g.pixel-to-vmin(-0.74px)),
      $subtitle-1:     matt.define-typography-level(g.pixel-to-vmin(24px),  g.pixel-to-vmin(29px),  600, $letter-spacing: g.pixel-to-vmin(-0.47px)),
      $subtitle-2:     matt.define-typography-level(g.pixel-to-vmin(16px),  g.pixel-to-vmin(19px),  500, $letter-spacing: g.pixel-to-vmin(-0.18px)),
      $body-1:         matt.define-typography-level(g.pixel-to-vmin(14px),  g.pixel-to-vmin(17px),  400, $letter-spacing: g.pixel-to-vmin(-0.18px)),
      $body-2:         matt.define-typography-level(g.pixel-to-vmin(16px),  g.pixel-to-vmin(19px),  400, $letter-spacing: g.pixel-to-vmin(-0.18px)),
      $caption:        matt.define-typography-level(g.pixel-to-vmin(12px),  g.pixel-to-vmin(14.25px),  400, $letter-spacing: g.pixel-to-vmin(-0.18px)),
      $button:         matt.define-typography-level(g.pixel-to-vmin(16px),  g.pixel-to-vmin(19px),  500, $letter-spacing: g.pixel-to-vmin(-0.18px)),
      // IMPORTANT: Line-height must be unit-less fraction of the font-size.
      // $in:            matt.define-typography-level(16px, calc(19/16), 400, $letter-spacing: -0.18px),
    ),
    (
      'input': matt.define-typography-level(g.pixel-to-vmin(16px), calc(19/11), 400, $letter-spacing: -0.18px),
      'body-1-custom': matt.define-typography-level(g.pixel-to-vmin(18px),  g.pixel-to-vmin(22px),  600, $letter-spacing: g.pixel-to-vmin(-0.26px)),
    )
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($grow-typography-config);
@include matt.all-component-typographies($grow-typography-config);
@include matt.core();

// Available color palettes: https://material.io/design/color/
$portal2-primary: matt.define-palette(g.$grow-primary-palette);
$portal2-accent: matt.define-palette(g.$grow-accent-palette, A200, A100, A400);
$portal2-warn: matt.define-palette(g.$grow-warn-palette);
// $portal2-info: matt.define-palette(g.$grow-info-palette);
// $portal2-orange: matt.define-palette(g.$grow-orange-palette);

@include g.global-customisation();
@include g.bootstrap-ext();

.dark-theme {
  $portal2-dark-theme: matt.define-dark-theme((
    color: (
      primary: $portal2-primary,
      accent: $portal2-accent,
      warn: $portal2-warn,
    )
  ));
  $foreground-palette: map-get($portal2-dark-theme, foreground);
  $background-palette: map-get($portal2-dark-theme, background);
  @include matt.all-component-themes($portal2-dark-theme);
  @include g.mat-theme-to-css-variables($portal2-dark-theme);

  // custom typography
  //  NOTE: for component global styling see _mat-mdc-mixins.scss
  .mat-mdc-form-field, .mat-mdc-select {
    @include matt.typography-level($grow-typography-config, 'input');
  }
  .mat-mdc-select-panel, .mat-mdc-autocomplete-panel {
    .mat-mdc-option {
      @include matt.typography-level($grow-typography-config, 'input');
    }
  }
  .mat-mdc-menu-panel {
    .mat-mdc-menu-item {
      .mdc-list-item__primary-text {
        @include matt.typography-level($grow-typography-config, 'input');
      }
    }
  }
  .mat-mdc-table {
    .mat-mdc-header-row {
      .mat-mdc-header-cell {
        font-size: g.pixel-to-vmin(14px);
        font-weight: 500;
      }
    }
    .mat-mdc-row {
      .mat-mdc-cell {
        font-size: g.pixel-to-vmin(14px);
        font-weight: 400;
      }
    }
  }
  .mat-mdc-tab-group {
    .mat-mdc-tab-header {
      .mat-mdc-tab-label-container {
        .mdc-tab__text-label {
          font-size: g.pixel-to-vmin(14px);
        }
      }
    }
  }
  .mat-h4-custom {
    @include matt.typography-level($grow-typography-config, 'body-1-custom');
    margin: 0 0 g.pixel-to-vmin(16px) 0;
  }

  @include g.theme-customisation();
  @include g.custom-css-variables(true);

  // additional
  // $additional-light-theme: matt.define-light-theme((
  //   color: (
  //     primary: $portal2-info,
  //     accent: $portal2-orange,
  //     warn: $portal2-warn,
  //   )
  // ));
  // @include g.mat-theme-to-css-variables($additional-light-theme, 'additional');
}

.light-theme {
  $portal2-light-theme: matt.define-light-theme((
    color: (
      primary: $portal2-primary,
      accent: $portal2-accent,
      warn: $portal2-warn,
    )
  ));
  $foreground-palette: map-get($portal2-light-theme, foreground);
  $background-palette: map-get($portal2-light-theme, background);
  @include matt.all-component-themes($portal2-light-theme);
  @include g.mat-theme-to-css-variables($portal2-light-theme);

  // custom typography
  //  NOTE: for component global styling see _mat-mdc-mixins.scss
  .mat-mdc-form-field, .mat-mdc-select {
    @include matt.typography-level($grow-typography-config, 'input');
  }
  .mat-mdc-select-panel, .mat-mdc-autocomplete-panel {
    .mat-mdc-option {
      @include matt.typography-level($grow-typography-config, 'input');
    }
  }
  .mat-mdc-menu-panel {
    .mat-mdc-menu-item {
      .mdc-list-item__primary-text {
        @include matt.typography-level($grow-typography-config, 'input');
      }
    }
  }
  .mat-mdc-table {
    .mat-mdc-header-row {
      .mat-mdc-header-cell {
        font-size: g.pixel-to-vmin(14px);
        font-weight: 500;
      }
    }
    .mat-mdc-row {
      .mat-mdc-cell {
        font-size: g.pixel-to-vmin(14px);
        font-weight: 400;
      }
    }
  }
  .mat-mdc-tab-group {
    .mat-mdc-tab-header {
      .mat-mdc-tab-label-container {
        .mdc-tab__text-label {
          font-size: g.pixel-to-vmin(14px);
        }
      }
    }
  }
  .mat-h4-custom {
    @include matt.typography-level($grow-typography-config, 'body-1-custom');
    margin: 0 0 g.pixel-to-vmin(16px) 0;
  }

  @include g.theme-customisation();
  @include g.custom-css-variables(false);

  // additional
  // $additional-light-theme: matt.define-light-theme((
  //   color: (
  //     primary: $portal2-info,
  //     accent: $portal2-orange,
  //     warn: $portal2-warn,
  //   )
  // ));
  // @include g.mat-theme-to-css-variables($additional-light-theme, 'additional');
}


/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
html {
  font-size: g.$base-font-size;
  body { margin: 0; font-family: Inter, "Helvetica Neue", sans-serif; }
  .light-theme .mat-app-background, .light-theme.mat-app-background{
    // background-color: #F5F5F5!important;
    // background-color: var(--portal2-sg-grey-lighten-4)!important;
    background-color: var(--mat-app-background-color)!important;
  }

  // -ms-overflow-style: none;
  // scrollbar-width: none;
  // ::-webkit-scrollbar {
  //   display: none;
  // }

  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: g.pixel-to-vmin(10px);
    height: g.pixel-to-vmin(10px);
  }
  ::-webkit-scrollbar-thumb {
    // background-color: var(--portal2-sg-grey-lighten-2);
    // border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    // background: var(--portal2-sg-white);
  }

  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
