@use "sass:color";
@use "@angular/material" as mat;
@use "./variables" as gvariables;
@use "./functions" as gfunctions;
@use "./mixins" as gmixins;


@mixin g-mat-mdc-slide-toggle-bar() {

  .mat-mdc-slide-toggle{
    .mdc-form-field{
      &:hover {
        outline: none;
      }

      &:active {
        outline: none;
      }

      &:focus {
        outline: none;
      }
    }
    .mdc-switch{

      &:hover {
        outline: none;
      }

      &:active {
        outline: none;
      }

      &:focus {
        outline: none !important;
      }
  }


  }

}


// mat-button-toggle-group
@mixin g-mat-mdc-button-toggle() {
  .mat-button-toggle-group {
    width: 100%;
    margin-bottom: gfunctions.pixel-to-vmin(40px);
    border-radius: gfunctions.pixel-to-vmin(7px);
    border: none !important;

    .mat-button-toggle {
      width: 100%;
      padding: 0px;
      margin: 0px;
      border-left: none !important;
      background-color: var(--portal2-sg-grey-lighten-5);

      &.mat-button-toggle-checked {
        background-color: var(--portal2-primary-default);
        color: var(--portal2-sg-green-darken-4-contrast);
        font-weight: 500;
        box-shadow: gfunctions.pixel-to-vmin(4px) 0px gfunctions.pixel-to-vmin(10px) rgba(0, 0, 0, 0.5);
        // .mat-button-toggle-button {
        // }
        .mat-button-toggle-focus-overlay {
          border-bottom: solid gfunctions.pixel-to-vmin(48px);
        }
      }

      &.mat-button-toggle-disabled {
        color: var(--portal2-sg-grey-lighten-1);
      }

      .mat-button-toggle-label-content {
      }
    }
  }
}


// mat-checkbox
@mixin g-mat-mdc-checkbox() {
  .mat-mdc-checkbox {
    .mdc-form-field {
      .mdc-checkbox {
        margin: calc((var(--mdc-checkbox-touch-target-size, 40px) - 50px) / 2);
      }
      .mdc-label {
        margin: 0;
      }
    }
  }
}

// mat-radio-group
@mixin g-mat-mdc-radio-group() {
  .mat-mdc-radio-group {
  }
}

// mat-tab
@mixin g-mat-mdc-tab() {
  .mat-mdc-tab-group {
    .mat-mdc-tab-header {
      .mat-mdc-tab-label-container {
        .mat-mdc-tab-labels {
          .mat-mdc-tab {
            padding-right: gfunctions.pixel-to-vmin(10px);
            padding-left: gfunctions.pixel-to-vmin(10px);

            .mdc-tab__content {
              .mdc-tab__text-label {
                color: var(--portal2-sg-blue-grey-darken-3);

                .mdi { // if there is an icon
                  padding-right: gfunctions.pixel-to-vmin(10px);
                }
              }
            }

            &.mdc-tab--active { // active tab
              .mdc-tab__content {
                .mdc-tab__text-label {
                  color: var(--portal2-primary-default);
                }
              }
            }

            .mdc-tab-indicator {
              .mdc-tab-indicator__content {
              }
            }
          }
        }
      }
    }

    .mat-mdc-tab-body-wrapper {
      padding-top: gfunctions.pixel-to-vmin(20px);

      .mat-mdc-tab-body {
        .mat-mdc-tab-body-content {
          overflow: hidden;
        }
      }
    }
  }
}


// mat-menu
@mixin g-mat-mdc-menu() {
  .cdk-overlay-container {
    .mat-mdc-menu-panel {
      max-width: none !important;

      .mat-mdc-menu-content {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        .mdc-list-item__primary-text {
          width: 90%;
        }
      }

      &.icon-menu {
        .mat-mdc-menu-content {
          .mdc-list-item__primary-text {
            .mdi {
              margin: gfunctions.pixel-to-vmin(10px);
            }

            width: 100%;
          }
        }
      }

      .mat-mdc-menu-item {
        display: block;
      }
    }
  }
}

// popup overlay
@mixin g-mat-mdc-cdk-overlay() {
  .cdk-overlay-pane {
    overflow-y: auto !important;

    &.mat-mdc-tooltip-panel {
      overflow-y: inherit !important;
    }
  }
}

// mat-autocomplete
@mixin g-mat-mdc-autocomplete() {
  .cdk-overlay-container { // auto-complete stuff as well  TODO: group them together
    .mat-mdc-autocomplete-panel {
      .mat-mdc-option {
        white-space: normal !important;
        line-height: normal !important;
        font-size: gfunctions.pixel-to-vmin(16px);

        .option-title {
        }

        .option-details {
          color: var(--portal2-sg-grey-darken-1);
        }
      }
    }
  }
}

// mat-tree
@mixin g-mat-mdc-tree() {
  .mat-tree {
    // background: var(--portal2-custom-background-color);
  }
}

// mat-sidenav
@mixin g-mat-mdc-sidenav() {
  .mat-sidenav-container {
    // background: var(--portal2-custom-background-color);

    .mat-sidenav {
      // background: var(--portal2-custom-background-color);
    }
  }
}

// mat-card
@mixin g-mat-mdc-card() {
  .mat-mdc-card {
    border-radius: gfunctions.pixel-to-vmin(7px);
    box-shadow: none !important;
    border: 1px solid var(--portal2-bluegrey-lighten-5)!important;
    // override changes introduced by angular-material 14
    .mat-divider-horizontal.mat-divider-inset {
      position: static;
      margin: 0
    }
  }
}

// mat-select
@mixin g-mat-mdc-select() {
  .mat-mdc-select {
    .mat-mdc-select-trigger {
      .mat-mdc-select-arrow-wrapper {
        transform: translateY(40%);
      }

      .mat-mdc-select-value {
        position: relative;
        top: gfunctions.pixel-to-vmin(7px);

        .mat-mdc-select-value-text {
          .mat-mdc-select-value-min-line {
          }
        }
      }
    }
  }
  .cdk-overlay-container {
    .mat-mdc-select-panel {
      min-width: 100% !important;
    }
  }
}



// mat-form-fields
@mixin g-mat-mdc-form-field() {
  $color: var(--portal2-sg-grey-darken-1); // title color
  $hoverColor: var(--portal2-primary-default);
  $nonHoverColor: var(--portal2-sg-grey-lighten-2);
  $hoverBorder: gfunctions.pixel-to-vmin(2px) solid $hoverColor;
  $nonHoverBorder: gfunctions.pixel-to-vmin(1px) solid $nonHoverColor;

  // datepicker form field popup
  .cdk-overlay-container {
    .cdk-overlay-pane {
      &.mat-datepicker-popup {
        overflow-y: hidden !important;;
      }
    }
  }


  .mat-mdc-form-field {
     .mdc-notched-outline__notch {
      border-left: 0px solid !important;
    }
    width: 100%;
    // not hover & not focused
    &:not(.mat-form-field-invalid):not(.mat-focused) {
      color: $color !important;

      .mdc-notched-outline {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing {
          border-color: $nonHoverColor !important;
          border-top: $nonHoverBorder !important;
          border-bottom: $nonHoverBorder !important;
        }

        .mdc-notched-outline__leading {
          border-left: $nonHoverBorder !important;
        }

        .mdc-notched-outline__trailing {
          border-right: $nonHoverBorder !important;
        }
        .mdc-notched-outline__notch {
          border-color: $nonHoverColor !important;
          border-bottom: $nonHoverBorder !important;

          .mdc-floating-label {
            color: $color !important;
          }
        }
      }
    }

    // hover
    &:not(.mat-form-field-invalid):hover {
      color: var(--portal2-primary-default);

      .mdc-notched-outline {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing {
          border-color: $hoverColor !important;
          border-top: $hoverBorder !important;
          border-bottom: $hoverBorder !important;
        }

        .mdc-notched-outline__leading {
          border-left: $hoverBorder !important;
        }

        .mdc-notched-outline__trailing {
          border-right: $hoverBorder !important;
        }

        .mdc-notched-outline__notch {
          border-color: $hoverColor !important;
          border-bottom: $hoverBorder !important;
          .mdc-floating-label {
            color: $hoverColor !important;
          }
        }
      }
    }

    // form-field itself
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        // border of mat input
        .mdc-notched-outline {
          // height: 95%;
          border-radius: gfunctions.pixel-to-vmin(7px) !important;

          .mdc-notched-outline__leading
          .mdc-notched-outline__trailing {
          }

          .mdc-notched-outline__leading {
          }

          .mdc-notched-outline__trailing {
          }

          .mdc-notched-outline__notch {
            .mdc-floating-label {
            }
          }
        }

        // content of form field itself
        .mat-mdc-form-field-infix {
          border: none;
          min-height: gfunctions.pixel-to-vmin(50px);
          padding: gfunctions.pixel-to-vmin(5px) gfunctions.pixel-to-vmin(5px);

          // native html input element
          .mat-mdc-input-element {
            caret-color: auto;
            margin-top: gfunctions.pixel-to-vmin(8px);
            overflow: hidden;
            text-overflow: ellipsis;
            // max-width: 95%;
            &::placeholder { // style placeholder
            }

            &.mat-mdc-form-field-textarea-control { // textarea
              margin-bottom: gfunctions.pixel-to-vmin(8px);
            }
          }
        }

        // prefix
        .mat-mdc-form-field-icon-prefix {
          padding: 0px gfunctions.pixel-to-vmin(4px) 0 gfunctions.pixel-to-vmin(8px);

          span {
            display: inline-block;
            padding: 0px 0px 0px gfunctions.pixel-to-vmin(20px);
          }
        }

        // postfix
        .mat-mdc-form-field-icon-suffix {
          span {
            display: inline-block;
            padding: 0px gfunctions.pixel-to-vmin(20px) 0px 0px;
          }
        }
      }
    }

    // error message / hint
    .mat-mdc-form-field-subscript-wrapper {
      margin-top: gfunctions.pixel-to-vmin(0px);
      margin-bottom: gfunctions.pixel-to-vmin(8px);

      .mat-mdc-form-field-error-wrapper {
        .mat-mdc-form-field-error {
          color: var(--portal2-warn-default) !important;
          font-size: var(--mat-form-field-subscript-text-size) !important;
        }
      }
    }
  }
}


// mat-button
@mixin g-mat-mdc-button() {

  .mat-mdc-button-base{
    &:hover {
      outline: none;
    }

    &:active {
      outline: none;
    }

    &:focus {
      outline: none;
    }
  }

  // mat-flat-button
  .mat-mdc-outlined-button {
    padding: gfunctions.pixel-to-vmin(23px) gfunctions.pixel-to-vmin(32px);
    // color: var(--portal2-primary-lighter-contrast) !important;
    border: gfunctions.pixel-to-vmin(1px) solid var(--portal2-primary-default) !important;
    border-radius: gfunctions.pixel-to-vmin(7px);
    background-color: transparent;

    &:hover {
      background: var(--portal2-sg-deep-purple-lighten-5);
      border: gfunctions.pixel-to-vmin(2px) solid var(--portal2-primary-default)
    }

    &.mat-warn {
      border-color: var(--portal2-warn-default) !important;
      color: var(--portal2-warn-default) !important;
    }

    &.mat-accent {
      border-color: var(--portal2-background-status-bar) !important;
    }
  }
  .mat-mdc-outlined-button:disabled {
    border-radius: gfunctions.pixel-to-vmin(7px);
    border: gfunctions.pixel-to-vmin(2px) solid var(--portal2-sg-deep-purple-lighten-1) !important;
    color: var(--portal2-sg-blue-grey-darken-3) !important;

    &:hover {
      background: transparent;
    }
  }

  // mat-stroke-button
  .mat-mdc-unelevated-button {
    border-radius: gfunctions.pixel-to-vmin(7px);
    border: gfunctions.pixel-to-vmin(1px) solid var(--portal2-primary-default) !important;
    padding: gfunctions.pixel-to-vmin(23px) gfunctions.pixel-to-vmin(32px);
    background-color: transparent;

    &.mat-warn {
      border-color: var(--portal2-warn-default) !important;
      color: var(--portal2-primary-default-contrast) !important;

    }
  }
  .mat-mdc-unelevated-button:disabled {
    background: var(--portal2-sg-light-blue-lighten-5-contrast) !important;
    color: var(--portal2-sg-blue-grey-darken-3) !important;
    border-color: var(--portal2-sg-light-blue-lighten-5-contrast) !important;
    // background-color: var(--portal2-sg-blue-grey-lighten-4) !important;
    // color: var(--portal2-primary-default-contrast) !important;
    // border-color: var(--portal2-sg-blue-grey-lighten-4) !important;

    &:hover {
      border: 2px solid transparent;
    }
  }

  // custom styles
  .custom-button {
    border: gfunctions.pixel-to-vmin(1px) solid rgba(0, 0, 0, 0.12) !important;
    background-color: var(--portal2-sg-grey-lighten-4);
    color: var(--portal2-sg-grey-lighten-4-contrast);
    padding: gfunctions.pixel-to-vmin(12px) gfunctions.pixel-to-vmin(11px);
    cursor: pointer;
    border-radius: gfunctions.pixel-to-vmin(7px);

    .icon {
      margin-right: gfunctions.pixel-to-vmin(10px);
      font-size: gfunctions.pixel-to-vmin(16px);
    }

    .text {
      font-size: gfunctions.pixel-to-vmin(17px);
    }
  }
}

// mat-dialog
@mixin g-mat-mdc-dialog() {
  // for model dialogue in mobile view
  .mat-mdc-dialog-container {
    .mdc-dialog__container {
      .mdc-dialog__content {
        @include gmixins.layout-bp(lt-md) {
          max-height: 100vh;
        }
        h3 {
          @include gmixins.layout-bp(lt-md) {
            font-size: gfunctions.pixel-to-vmin(19px);
          }
        }

        .mdi-close {
          @include gmixins.layout-bp(lt-md) {
            position: absolute;
            right: gfunctions.pixel-to-vmin(7px);
            top: gfunctions.pixel-to-vmin(-40px);
          }
        }

        .mat-primary {
          @include gmixins.layout-bp(lt-md) {
            min-width: gfunctions.pixel-to-vmin(121px);
          }
        }
      }
    }
  }
}

// mat-progress-bar
@mixin g-mat-mdc-progress-bar {
  .mat-mdc-progress-bar {
    --mdc-linear-progress-track-color: var(--grow-accent-palette-500) !important;
    --mdc-linear-progress-active-indicator-color: var(--grow-primary-palette-500) !important;
  }
}

@mixin theme-customisation() {

  @include g-mat-mdc-tree();

  @include g-mat-mdc-sidenav();

  @include g-mat-mdc-checkbox();

  @include g-mat-mdc-radio-group();

  @include g-mat-mdc-select();

  @include g-mat-mdc-tab();

  @include g-mat-mdc-form-field();

  @include g-mat-mdc-autocomplete();

  @include g-mat-mdc-card();

  @include g-mat-mdc-button();

  @include g-mat-mdc-menu();

  @include g-mat-mdc-cdk-overlay();

  @include g-mat-mdc-button-toggle();

  @include g-mat-mdc-slide-toggle-bar();

  @include g-mat-mdc-dialog();

  @include g-mat-mdc-progress-bar();
}
