@use "./mixins" as gmixins;


@mixin bootstrap-ext() {  // todo: refactoring use breakpoints in _mixins.scss
  // sm
  @include gmixins.layout-bp(lt-sm) {
    .w-lt-sm-25 {
      width: 25% !important;
    }

    .w-lt-sm-50 {
      width: 50% !important;
    }

    .w-lt-sm-75 {
      width: 75% !important;
    }

    .w-lt-sm-100 {
      width: 100% !important;
    }

    .w-lt-sm-auto {
      width: auto !important;
    }

    .m-lt-sm-0 {
      margin: 0 !important;
    }

    .mt-lt-sm-0,
    .my-lt-sm-0 {
      margin-top: 0 !important;
    }

    .mr-lt-sm-0,
    .mx-lt-sm-0 {
      margin-right: 0 !important;
    }

    .mb-lt-sm-0,
    .my-lt-sm-0 {
      margin-bottom: 0 !important;
    }

    .ml-lt-sm-0,
    .mx-lt-sm-0 {
      margin-left: 0 !important;
    }

    .m-lt-sm-1 {
      margin: 0.25rem !important;
    }

    .mt-lt-sm-1,
    .my-lt-sm-1 {
      margin-top: 0.25rem !important;
    }

    .mr-lt-sm-1,
    .mx-lt-sm-1 {
      margin-right: 0.25rem !important;
    }

    .mb-lt-sm-1,
    .my-lt-sm-1 {
      margin-bottom: 0.25rem !important;
    }

    .ml-lt-sm-1,
    .mx-lt-sm-1 {
      margin-left: 0.25rem !important;
    }

    .m-lt-sm-2 {
      margin: 0.5rem !important;
    }

    .mt-lt-sm-2,
    .my-lt-sm-2 {
      margin-top: 0.5rem !important;
    }

    .mr-lt-sm-2,
    .mx-lt-sm-2 {
      margin-right: 0.5rem !important;
    }

    .mb-lt-sm-2,
    .my-lt-sm-2 {
      margin-bottom: 0.5rem !important;
    }

    .ml-lt-sm-2,
    .mx-lt-sm-2 {
      margin-left: 0.5rem !important;
    }

    .m-lt-sm-3 {
      margin: 1rem !important;
    }

    .mt-lt-sm-3,
    .my-lt-sm-3 {
      margin-top: 1rem !important;
    }

    .mr-lt-sm-3,
    .mx-lt-sm-3 {
      margin-right: 1rem !important;
    }

    .mb-lt-sm-3,
    .my-lt-sm-3 {
      margin-bottom: 1rem !important;
    }

    .ml-lt-sm-3,
    .mx-lt-sm-3 {
      margin-left: 1rem !important;
    }

    .m-lt-sm-4 {
      margin: 1.5rem !important;
    }

    .mt-lt-sm-4,
    .my-lt-sm-4 {
      margin-top: 1.5rem !important;
    }

    .mr-lt-sm-4,
    .mx-lt-sm-4 {
      margin-right: 1.5rem !important;
    }

    .mb-lt-sm-4,
    .my-lt-sm-4 {
      margin-bottom: 1.5rem !important;
    }

    .ml-lt-sm-4,
    .mx-lt-sm-4 {
      margin-left: 1.5rem !important;
    }

    .m-lt-sm-5 {
      margin: 3rem !important;
    }

    .mt-lt-sm-5,
    .my-lt-sm-5 {
      margin-top: 3rem !important;
    }

    .mr-lt-sm-5,
    .mx-lt-sm-5 {
      margin-right: 3rem !important;
    }

    .mb-lt-sm-5,
    .my-lt-sm-5 {
      margin-bottom: 3rem !important;
    }

    .ml-lt-sm-5,
    .mx-lt-sm-5 {
      margin-left: 3rem !important;
    }

    .p-lt-sm-0 {
      padding: 0 !important;
    }

    .pt-lt-sm-0,
    .py-lt-sm-0 {
      padding-top: 0 !important;
    }

    .pr-lt-sm-0,
    .px-lt-sm-0 {
      padding-right: 0 !important;
    }

    .pb-lt-sm-0,
    .py-lt-sm-0 {
      padding-bottom: 0 !important;
    }

    .pl-lt-sm-0,
    .px-lt-sm-0 {
      padding-left: 0 !important;
    }

    .p-lt-sm-1 {
      padding: 0.25rem !important;
    }

    .pt-lt-sm-1,
    .py-lt-sm-1 {
      padding-top: 0.25rem !important;
    }

    .pr-lt-sm-1,
    .px-lt-sm-1 {
      padding-right: 0.25rem !important;
    }

    .pb-lt-sm-1,
    .py-lt-sm-1 {
      padding-bottom: 0.25rem !important;
    }

    .pl-lt-sm-1,
    .px-lt-sm-1 {
      padding-left: 0.25rem !important;
    }

    .p-lt-sm-2 {
      padding: 0.5rem !important;
    }

    .pt-lt-sm-2,
    .py-lt-sm-2 {
      padding-top: 0.5rem !important;
    }

    .pr-lt-sm-2,
    .px-lt-sm-2 {
      padding-right: 0.5rem !important;
    }

    .pb-lt-sm-2,
    .py-lt-sm-2 {
      padding-bottom: 0.5rem !important;
    }

    .pl-lt-sm-2,
    .px-lt-sm-2 {
      padding-left: 0.5rem !important;
    }

    .p-lt-sm-3 {
      padding: 1rem !important;
    }

    .pt-lt-sm-3,
    .py-lt-sm-3 {
      padding-top: 1rem !important;
    }

    .pr-lt-sm-3,
    .px-lt-sm-3 {
      padding-right: 1rem !important;
    }

    .pb-lt-sm-3,
    .py-lt-sm-3 {
      padding-bottom: 1rem !important;
    }

    .pl-lt-sm-3,
    .px-lt-sm-3 {
      padding-left: 1rem !important;
    }

    .p-lt-sm-4 {
      padding: 1.5rem !important;
    }

    .pt-lt-sm-4,
    .py-lt-sm-4 {
      padding-top: 1.5rem !important;
    }

    .pr-lt-sm-4,
    .px-lt-sm-4 {
      padding-right: 1.5rem !important;
    }

    .pb-lt-sm-4,
    .py-lt-sm-4 {
      padding-bottom: 1.5rem !important;
    }

    .pl-lt-sm-4,
    .px-lt-sm-4 {
      padding-left: 1.5rem !important;
    }

    .p-lt-sm-5 {
      padding: 3rem !important;
    }

    .pt-lt-sm-5,
    .py-lt-sm-5 {
      padding-top: 3rem !important;
    }

    .pr-lt-sm-5,
    .px-lt-sm-5 {
      padding-right: 3rem !important;
    }

    .pb-lt-sm-5,
    .py-lt-sm-5 {
      padding-bottom: 3rem !important;
    }

    .pl-lt-sm-5,
    .px-lt-sm-5 {
      padding-left: 3rem !important;
    }

    .m-lt-sm-n1 {
      margin: -0.25rem !important;
    }

    .mt-lt-sm-n1,
    .my-lt-sm-n1 {
      margin-top: -0.25rem !important;
    }

    .mr-lt-sm-n1,
    .mx-lt-sm-n1 {
      margin-right: -0.25rem !important;
    }

    .mb-lt-sm-n1,
    .my-lt-sm-n1 {
      margin-bottom: -0.25rem !important;
    }

    .ml-lt-sm-n1,
    .mx-lt-sm-n1 {
      margin-left: -0.25rem !important;
    }

    .m-lt-sm-n2 {
      margin: -0.5rem !important;
    }

    .mt-lt-sm-n2,
    .my-lt-sm-n2 {
      margin-top: -0.5rem !important;
    }

    .mr-lt-sm-n2,
    .mx-lt-sm-n2 {
      margin-right: -0.5rem !important;
    }

    .mb-lt-sm-n2,
    .my-lt-sm-n2 {
      margin-bottom: -0.5rem !important;
    }

    .ml-lt-sm-n2,
    .mx-lt-sm-n2 {
      margin-left: -0.5rem !important;
    }

    .m-lt-sm-n3 {
      margin: -1rem !important;
    }

    .mt-lt-sm-n3,
    .my-lt-sm-n3 {
      margin-top: -1rem !important;
    }

    .mr-lt-sm-n3,
    .mx-lt-sm-n3 {
      margin-right: -1rem !important;
    }

    .mb-lt-sm-n3,
    .my-lt-sm-n3 {
      margin-bottom: -1rem !important;
    }

    .ml-lt-sm-n3,
    .mx-lt-sm-n3 {
      margin-left: -1rem !important;
    }

    .m-lt-sm-n4 {
      margin: -1.5rem !important;
    }

    .mt-lt-sm-n4,
    .my-lt-sm-n4 {
      margin-top: -1.5rem !important;
    }

    .mr-lt-sm-n4,
    .mx-lt-sm-n4 {
      margin-right: -1.5rem !important;
    }

    .mb-lt-sm-n4,
    .my-lt-sm-n4 {
      margin-bottom: -1.5rem !important;
    }

    .ml-lt-sm-n4,
    .mx-lt-sm-n4 {
      margin-left: -1.5rem !important;
    }

    .m-lt-sm-n5 {
      margin: -3rem !important;
    }

    .mt-lt-sm-n5,
    .my-lt-sm-n5 {
      margin-top: -3rem !important;
    }

    .mr-lt-sm-n5,
    .mx-lt-sm-n5 {
      margin-right: -3rem !important;
    }

    .mb-lt-sm-n5,
    .my-lt-sm-n5 {
      margin-bottom: -3rem !important;
    }

    .ml-lt-sm-n5,
    .mx-lt-sm-n5 {
      margin-left: -3rem !important;
    }

    .m-lt-sm-auto {
      margin: auto !important;
    }

    .mt-lt-sm-auto,
    .my-lt-sm-auto {
      margin-top: auto !important;
    }

    .mr-lt-sm-auto,
    .mx-lt-sm-auto {
      margin-right: auto !important;
    }

    .mb-lt-sm-auto,
    .my-lt-sm-auto {
      margin-bottom: auto !important;
    }

    .ml-lt-sm-auto,
    .mx-lt-sm-auto {
      margin-left: auto !important;
    }
  }

  // md
  @include gmixins.layout-bp(lt-md) {
    .w-lt-md-25 {
      width: 25% !important;
    }

    .w-lt-md-50 {
      width: 50% !important;
    }

    .w-lt-md-75 {
      width: 75% !important;
    }

    .w-lt-md-100 {
      width: 100% !important;
    }

    .w-lt-md-auto {
      width: auto !important;
    }

    .m-lt-md-0 {
      margin: 0 !important;
    }

    .mt-lt-md-0,
    .my-lt-md-0 {
      margin-top: 0 !important;
    }

    .mr-lt-md-0,
    .mx-lt-md-0 {
      margin-right: 0 !important;
    }

    .mb-lt-md-0,
    .my-lt-md-0 {
      margin-bottom: 0 !important;
    }

    .ml-lt-md-0,
    .mx-lt-md-0 {
      margin-left: 0 !important;
    }

    .m-lt-md-1 {
      margin: 0.25rem !important;
    }

    .mt-lt-md-1,
    .my-lt-md-1 {
      margin-top: 0.25rem !important;
    }

    .mr-lt-md-1,
    .mx-lt-md-1 {
      margin-right: 0.25rem !important;
    }

    .mb-lt-md-1,
    .my-lt-md-1 {
      margin-bottom: 0.25rem !important;
    }

    .ml-lt-md-1,
    .mx-lt-md-1 {
      margin-left: 0.25rem !important;
    }

    .m-lt-md-2 {
      margin: 0.5rem !important;
    }

    .mt-lt-md-2,
    .my-lt-md-2 {
      margin-top: 0.5rem !important;
    }

    .mr-lt-md-2,
    .mx-lt-md-2 {
      margin-right: 0.5rem !important;
    }

    .mb-lt-md-2,
    .my-lt-md-2 {
      margin-bottom: 0.5rem !important;
    }

    .ml-lt-md-2,
    .mx-lt-md-2 {
      margin-left: 0.5rem !important;
    }

    .m-lt-md-3 {
      margin: 1rem !important;
    }

    .mt-lt-md-3,
    .my-lt-md-3 {
      margin-top: 1rem !important;
    }

    .mr-lt-md-3,
    .mx-lt-md-3 {
      margin-right: 1rem !important;
    }

    .mb-lt-md-3,
    .my-lt-md-3 {
      margin-bottom: 1rem !important;
    }

    .ml-lt-md-3,
    .mx-lt-md-3 {
      margin-left: 1rem !important;
    }

    .m-lt-md-4 {
      margin: 1.5rem !important;
    }

    .mt-lt-md-4,
    .my-lt-md-4 {
      margin-top: 1.5rem !important;
    }

    .mr-lt-md-4,
    .mx-lt-md-4 {
      margin-right: 1.5rem !important;
    }

    .mb-lt-md-4,
    .my-lt-md-4 {
      margin-bottom: 1.5rem !important;
    }

    .ml-lt-md-4,
    .mx-lt-md-4 {
      margin-left: 1.5rem !important;
    }

    .m-lt-md-5 {
      margin: 3rem !important;
    }

    .mt-lt-md-5,
    .my-lt-md-5 {
      margin-top: 3rem !important;
    }

    .mr-lt-md-5,
    .mx-lt-md-5 {
      margin-right: 3rem !important;
    }

    .mb-lt-md-5,
    .my-lt-md-5 {
      margin-bottom: 3rem !important;
    }

    .ml-lt-md-5,
    .mx-lt-md-5 {
      margin-left: 3rem !important;
    }

    .p-lt-md-0 {
      padding: 0 !important;
    }

    .pt-lt-md-0,
    .py-lt-md-0 {
      padding-top: 0 !important;
    }

    .pr-lt-md-0,
    .px-lt-md-0 {
      padding-right: 0 !important;
    }

    .pb-lt-md-0,
    .py-lt-md-0 {
      padding-bottom: 0 !important;
    }

    .pl-lt-md-0,
    .px-lt-md-0 {
      padding-left: 0 !important;
    }

    .p-lt-md-1 {
      padding: 0.25rem !important;
    }

    .pt-lt-md-1,
    .py-lt-md-1 {
      padding-top: 0.25rem !important;
    }

    .pr-lt-md-1,
    .px-lt-md-1 {
      padding-right: 0.25rem !important;
    }

    .pb-lt-md-1,
    .py-lt-md-1 {
      padding-bottom: 0.25rem !important;
    }

    .pl-lt-md-1,
    .px-lt-md-1 {
      padding-left: 0.25rem !important;
    }

    .p-lt-md-2 {
      padding: 0.5rem !important;
    }

    .pt-lt-md-2,
    .py-lt-md-2 {
      padding-top: 0.5rem !important;
    }

    .pr-lt-md-2,
    .px-lt-md-2 {
      padding-right: 0.5rem !important;
    }

    .pb-lt-md-2,
    .py-lt-md-2 {
      padding-bottom: 0.5rem !important;
    }

    .pl-lt-md-2,
    .px-lt-md-2 {
      padding-left: 0.5rem !important;
    }

    .p-lt-md-3 {
      padding: 1rem !important;
    }

    .pt-lt-md-3,
    .py-lt-md-3 {
      padding-top: 1rem !important;
    }

    .pr-lt-md-3,
    .px-lt-md-3 {
      padding-right: 1rem !important;
    }

    .pb-lt-md-3,
    .py-lt-md-3 {
      padding-bottom: 1rem !important;
    }

    .pl-lt-md-3,
    .px-lt-md-3 {
      padding-left: 1rem !important;
    }

    .p-lt-md-4 {
      padding: 1.5rem !important;
    }

    .pt-lt-md-4,
    .py-lt-md-4 {
      padding-top: 1.5rem !important;
    }

    .pr-lt-md-4,
    .px-lt-md-4 {
      padding-right: 1.5rem !important;
    }

    .pb-lt-md-4,
    .py-lt-md-4 {
      padding-bottom: 1.5rem !important;
    }

    .pl-lt-md-4,
    .px-lt-md-4 {
      padding-left: 1.5rem !important;
    }

    .p-lt-md-5 {
      padding: 3rem !important;
    }

    .pt-lt-md-5,
    .py-lt-md-5 {
      padding-top: 3rem !important;
    }

    .pr-lt-md-5,
    .px-lt-md-5 {
      padding-right: 3rem !important;
    }

    .pb-lt-md-5,
    .py-lt-md-5 {
      padding-bottom: 3rem !important;
    }

    .pl-lt-md-5,
    .px-lt-md-5 {
      padding-left: 3rem !important;
    }

    .m-lt-md-n1 {
      margin: -0.25rem !important;
    }

    .mt-lt-md-n1,
    .my-lt-md-n1 {
      margin-top: -0.25rem !important;
    }

    .mr-lt-md-n1,
    .mx-lt-md-n1 {
      margin-right: -0.25rem !important;
    }

    .mb-lt-md-n1,
    .my-lt-md-n1 {
      margin-bottom: -0.25rem !important;
    }

    .ml-lt-md-n1,
    .mx-lt-md-n1 {
      margin-left: -0.25rem !important;
    }

    .m-lt-md-n2 {
      margin: -0.5rem !important;
    }

    .mt-lt-md-n2,
    .my-lt-md-n2 {
      margin-top: -0.5rem !important;
    }

    .mr-lt-md-n2,
    .mx-lt-md-n2 {
      margin-right: -0.5rem !important;
    }

    .mb-lt-md-n2,
    .my-lt-md-n2 {
      margin-bottom: -0.5rem !important;
    }

    .ml-lt-md-n2,
    .mx-lt-md-n2 {
      margin-left: -0.5rem !important;
    }

    .m-lt-md-n3 {
      margin: -1rem !important;
    }

    .mt-lt-md-n3,
    .my-lt-md-n3 {
      margin-top: -1rem !important;
    }

    .mr-lt-md-n3,
    .mx-lt-md-n3 {
      margin-right: -1rem !important;
    }

    .mb-lt-md-n3,
    .my-lt-md-n3 {
      margin-bottom: -1rem !important;
    }

    .ml-lt-md-n3,
    .mx-lt-md-n3 {
      margin-left: -1rem !important;
    }

    .m-lt-md-n4 {
      margin: -1.5rem !important;
    }

    .mt-lt-md-n4,
    .my-lt-md-n4 {
      margin-top: -1.5rem !important;
    }

    .mr-lt-md-n4,
    .mx-lt-md-n4 {
      margin-right: -1.5rem !important;
    }

    .mb-lt-md-n4,
    .my-lt-md-n4 {
      margin-bottom: -1.5rem !important;
    }

    .ml-lt-md-n4,
    .mx-lt-md-n4 {
      margin-left: -1.5rem !important;
    }

    .m-lt-md-n5 {
      margin: -3rem !important;
    }

    .mt-lt-md-n5,
    .my-lt-md-n5 {
      margin-top: -3rem !important;
    }

    .mr-lt-md-n5,
    .mx-lt-md-n5 {
      margin-right: -3rem !important;
    }

    .mb-lt-md-n5,
    .my-lt-md-n5 {
      margin-bottom: -3rem !important;
    }

    .ml-lt-md-n5,
    .mx-lt-md-n5 {
      margin-left: -3rem !important;
    }

    .m-lt-md-auto {
      margin: auto !important;
    }

    .mt-lt-md-auto,
    .my-lt-md-auto {
      margin-top: auto !important;
    }

    .mr-lt-md-auto,
    .mx-lt-md-auto {
      margin-right: auto !important;
    }

    .mb-lt-md-auto,
    .my-lt-md-auto {
      margin-bottom: auto !important;
    }

    .ml-lt-md-auto,
    .mx-lt-md-auto {
      margin-left: auto !important;
    }
  }

  // lg
  @include gmixins.layout-bp(lt-lg) {
    .w-lt-lg-25 {
      width: 25% !important;
    }

    .w-lt-lg-50 {
      width: 50% !important;
    }

    .w-lt-lg-75 {
      width: 75% !important;
    }

    .w-lt-lg-100 {
      width: 100% !important;
    }

    .w-lt-lg-auto {
      width: auto !important;
    }

    .m-lt-lg-0 {
      margin: 0 !important;
    }

    .mt-lt-lg-0,
    .my-lt-lg-0 {
      margin-top: 0 !important;
    }

    .mr-lt-lg-0,
    .mx-lt-lg-0 {
      margin-right: 0 !important;
    }

    .mb-lt-lg-0,
    .my-lt-lg-0 {
      margin-bottom: 0 !important;
    }

    .ml-lt-lg-0,
    .mx-lt-lg-0 {
      margin-left: 0 !important;
    }

    .m-lt-lg-1 {
      margin: 0.25rem !important;
    }

    .mt-lt-lg-1,
    .my-lt-lg-1 {
      margin-top: 0.25rem !important;
    }

    .mr-lt-lg-1,
    .mx-lt-lg-1 {
      margin-right: 0.25rem !important;
    }

    .mb-lt-lg-1,
    .my-lt-lg-1 {
      margin-bottom: 0.25rem !important;
    }

    .ml-lt-lg-1,
    .mx-lt-lg-1 {
      margin-left: 0.25rem !important;
    }

    .m-lt-lg-2 {
      margin: 0.5rem !important;
    }

    .mt-lt-lg-2,
    .my-lt-lg-2 {
      margin-top: 0.5rem !important;
    }

    .mr-lt-lg-2,
    .mx-lt-lg-2 {
      margin-right: 0.5rem !important;
    }

    .mb-lt-lg-2,
    .my-lt-lg-2 {
      margin-bottom: 0.5rem !important;
    }

    .ml-lt-lg-2,
    .mx-lt-lg-2 {
      margin-left: 0.5rem !important;
    }

    .m-lt-lg-3 {
      margin: 1rem !important;
    }

    .mt-lt-lg-3,
    .my-lt-lg-3 {
      margin-top: 1rem !important;
    }

    .mr-lt-lg-3,
    .mx-lt-lg-3 {
      margin-right: 1rem !important;
    }

    .mb-lt-lg-3,
    .my-lt-lg-3 {
      margin-bottom: 1rem !important;
    }

    .ml-lt-lg-3,
    .mx-lt-lg-3 {
      margin-left: 1rem !important;
    }

    .m-lt-lg-4 {
      margin: 1.5rem !important;
    }

    .mt-lt-lg-4,
    .my-lt-lg-4 {
      margin-top: 1.5rem !important;
    }

    .mr-lt-lg-4,
    .mx-lt-lg-4 {
      margin-right: 1.5rem !important;
    }

    .mb-lt-lg-4,
    .my-lt-lg-4 {
      margin-bottom: 1.5rem !important;
    }

    .ml-lt-lg-4,
    .mx-lt-lg-4 {
      margin-left: 1.5rem !important;
    }

    .m-lt-lg-5 {
      margin: 3rem !important;
    }

    .mt-lt-lg-5,
    .my-lt-lg-5 {
      margin-top: 3rem !important;
    }

    .mr-lt-lg-5,
    .mx-lt-lg-5 {
      margin-right: 3rem !important;
    }

    .mb-lt-lg-5,
    .my-lt-lg-5 {
      margin-bottom: 3rem !important;
    }

    .ml-lt-lg-5,
    .mx-lt-lg-5 {
      margin-left: 3rem !important;
    }

    .p-lt-lg-0 {
      padding: 0 !important;
    }

    .pt-lt-lg-0,
    .py-lt-lg-0 {
      padding-top: 0 !important;
    }

    .pr-lt-lg-0,
    .px-lt-lg-0 {
      padding-right: 0 !important;
    }

    .pb-lt-lg-0,
    .py-lt-lg-0 {
      padding-bottom: 0 !important;
    }

    .pl-lt-lg-0,
    .px-lt-lg-0 {
      padding-left: 0 !important;
    }

    .p-lt-lg-1 {
      padding: 0.25rem !important;
    }

    .pt-lt-lg-1,
    .py-lt-lg-1 {
      padding-top: 0.25rem !important;
    }

    .pr-lt-lg-1,
    .px-lt-lg-1 {
      padding-right: 0.25rem !important;
    }

    .pb-lt-lg-1,
    .py-lt-lg-1 {
      padding-bottom: 0.25rem !important;
    }

    .pl-lt-lg-1,
    .px-lt-lg-1 {
      padding-left: 0.25rem !important;
    }

    .p-lt-lg-2 {
      padding: 0.5rem !important;
    }

    .pt-lt-lg-2,
    .py-lt-lg-2 {
      padding-top: 0.5rem !important;
    }

    .pr-lt-lg-2,
    .px-lt-lg-2 {
      padding-right: 0.5rem !important;
    }

    .pb-lt-lg-2,
    .py-lt-lg-2 {
      padding-bottom: 0.5rem !important;
    }

    .pl-lt-lg-2,
    .px-lt-lg-2 {
      padding-left: 0.5rem !important;
    }

    .p-lt-lg-3 {
      padding: 1rem !important;
    }

    .pt-lt-lg-3,
    .py-lt-lg-3 {
      padding-top: 1rem !important;
    }

    .pr-lt-lg-3,
    .px-lt-lg-3 {
      padding-right: 1rem !important;
    }

    .pb-lt-lg-3,
    .py-lt-lg-3 {
      padding-bottom: 1rem !important;
    }

    .pl-lt-lg-3,
    .px-lt-lg-3 {
      padding-left: 1rem !important;
    }

    .p-lt-lg-4 {
      padding: 1.5rem !important;
    }

    .pt-lt-lg-4,
    .py-lt-lg-4 {
      padding-top: 1.5rem !important;
    }

    .pr-lt-lg-4,
    .px-lt-lg-4 {
      padding-right: 1.5rem !important;
    }

    .pb-lt-lg-4,
    .py-lt-lg-4 {
      padding-bottom: 1.5rem !important;
    }

    .pl-lt-lg-4,
    .px-lt-lg-4 {
      padding-left: 1.5rem !important;
    }

    .p-lt-lg-5 {
      padding: 3rem !important;
    }

    .pt-lt-lg-5,
    .py-lt-lg-5 {
      padding-top: 3rem !important;
    }

    .pr-lt-lg-5,
    .px-lt-lg-5 {
      padding-right: 3rem !important;
    }

    .pb-lt-lg-5,
    .py-lt-lg-5 {
      padding-bottom: 3rem !important;
    }

    .pl-lt-lg-5,
    .px-lt-lg-5 {
      padding-left: 3rem !important;
    }

    .m-lt-lg-n1 {
      margin: -0.25rem !important;
    }

    .mt-lt-lg-n1,
    .my-lt-lg-n1 {
      margin-top: -0.25rem !important;
    }

    .mr-lt-lg-n1,
    .mx-lt-lg-n1 {
      margin-right: -0.25rem !important;
    }

    .mb-lt-lg-n1,
    .my-lt-lg-n1 {
      margin-bottom: -0.25rem !important;
    }

    .ml-lt-lg-n1,
    .mx-lt-lg-n1 {
      margin-left: -0.25rem !important;
    }

    .m-lt-lg-n2 {
      margin: -0.5rem !important;
    }

    .mt-lt-lg-n2,
    .my-lt-lg-n2 {
      margin-top: -0.5rem !important;
    }

    .mr-lt-lg-n2,
    .mx-lt-lg-n2 {
      margin-right: -0.5rem !important;
    }

    .mb-lt-lg-n2,
    .my-lt-lg-n2 {
      margin-bottom: -0.5rem !important;
    }

    .ml-lt-lg-n2,
    .mx-lt-lg-n2 {
      margin-left: -0.5rem !important;
    }

    .m-lt-lg-n3 {
      margin: -1rem !important;
    }

    .mt-lt-lg-n3,
    .my-lt-lg-n3 {
      margin-top: -1rem !important;
    }

    .mr-lt-lg-n3,
    .mx-lt-lg-n3 {
      margin-right: -1rem !important;
    }

    .mb-lt-lg-n3,
    .my-lt-lg-n3 {
      margin-bottom: -1rem !important;
    }

    .ml-lt-lg-n3,
    .mx-lt-lg-n3 {
      margin-left: -1rem !important;
    }

    .m-lt-lg-n4 {
      margin: -1.5rem !important;
    }

    .mt-lt-lg-n4,
    .my-lt-lg-n4 {
      margin-top: -1.5rem !important;
    }

    .mr-lt-lg-n4,
    .mx-lt-lg-n4 {
      margin-right: -1.5rem !important;
    }

    .mb-lt-lg-n4,
    .my-lt-lg-n4 {
      margin-bottom: -1.5rem !important;
    }

    .ml-lt-lg-n4,
    .mx-lt-lg-n4 {
      margin-left: -1.5rem !important;
    }

    .m-lt-lg-n5 {
      margin: -3rem !important;
    }

    .mt-lt-lg-n5,
    .my-lt-lg-n5 {
      margin-top: -3rem !important;
    }

    .mr-lt-lg-n5,
    .mx-lt-lg-n5 {
      margin-right: -3rem !important;
    }

    .mb-lt-lg-n5,
    .my-lt-lg-n5 {
      margin-bottom: -3rem !important;
    }

    .ml-lt-lg-n5,
    .mx-lt-lg-n5 {
      margin-left: -3rem !important;
    }

    .m-lt-lg-auto {
      margin: auto !important;
    }

    .mt-lt-lg-auto,
    .my-lt-lg-auto {
      margin-top: auto !important;
    }

    .mr-lt-lg-auto,
    .mx-lt-lg-auto {
      margin-right: auto !important;
    }

    .mb-lt-lg-auto,
    .my-lt-lg-auto {
      margin-bottom: auto !important;
    }

    .ml-lt-lg-auto,
    .mx-lt-lg-auto {
      margin-left: auto !important;
    }
  }

  // xl
  @include gmixins.layout-bp(lt-xl) {
    .w-lt-xl-25 {
      width: 25% !important;
    }

    .w-lt-xl-50 {
      width: 50% !important;
    }

    .w-lt-xl-75 {
      width: 75% !important;
    }

    .w-lt-xl-100 {
      width: 100% !important;
    }

    .w-lt-xl-auto {
      width: auto !important;
    }

    .m-lt-xl-0 {
      margin: 0 !important;
    }

    .mt-lt-xl-0,
    .my-lt-xl-0 {
      margin-top: 0 !important;
    }

    .mr-lt-xl-0,
    .mx-lt-xl-0 {
      margin-right: 0 !important;
    }

    .mb-lt-xl-0,
    .my-lt-xl-0 {
      margin-bottom: 0 !important;
    }

    .ml-lt-xl-0,
    .mx-lt-xl-0 {
      margin-left: 0 !important;
    }

    .m-lt-xl-1 {
      margin: 0.25rem !important;
    }

    .mt-lt-xl-1,
    .my-lt-xl-1 {
      margin-top: 0.25rem !important;
    }

    .mr-lt-xl-1,
    .mx-lt-xl-1 {
      margin-right: 0.25rem !important;
    }

    .mb-lt-xl-1,
    .my-lt-xl-1 {
      margin-bottom: 0.25rem !important;
    }

    .ml-lt-xl-1,
    .mx-lt-xl-1 {
      margin-left: 0.25rem !important;
    }

    .m-lt-xl-2 {
      margin: 0.5rem !important;
    }

    .mt-lt-xl-2,
    .my-lt-xl-2 {
      margin-top: 0.5rem !important;
    }

    .mr-lt-xl-2,
    .mx-lt-xl-2 {
      margin-right: 0.5rem !important;
    }

    .mb-lt-xl-2,
    .my-lt-xl-2 {
      margin-bottom: 0.5rem !important;
    }

    .ml-lt-xl-2,
    .mx-lt-xl-2 {
      margin-left: 0.5rem !important;
    }

    .m-lt-xl-3 {
      margin: 1rem !important;
    }

    .mt-lt-xl-3,
    .my-lt-xl-3 {
      margin-top: 1rem !important;
    }

    .mr-lt-xl-3,
    .mx-lt-xl-3 {
      margin-right: 1rem !important;
    }

    .mb-lt-xl-3,
    .my-lt-xl-3 {
      margin-bottom: 1rem !important;
    }

    .ml-lt-xl-3,
    .mx-lt-xl-3 {
      margin-left: 1rem !important;
    }

    .m-lt-xl-4 {
      margin: 1.5rem !important;
    }

    .mt-lt-xl-4,
    .my-lt-xl-4 {
      margin-top: 1.5rem !important;
    }

    .mr-lt-xl-4,
    .mx-lt-xl-4 {
      margin-right: 1.5rem !important;
    }

    .mb-lt-xl-4,
    .my-lt-xl-4 {
      margin-bottom: 1.5rem !important;
    }

    .ml-lt-xl-4,
    .mx-lt-xl-4 {
      margin-left: 1.5rem !important;
    }

    .m-lt-xl-5 {
      margin: 3rem !important;
    }

    .mt-lt-xl-5,
    .my-lt-xl-5 {
      margin-top: 3rem !important;
    }

    .mr-lt-xl-5,
    .mx-lt-xl-5 {
      margin-right: 3rem !important;
    }

    .mb-lt-xl-5,
    .my-lt-xl-5 {
      margin-bottom: 3rem !important;
    }

    .ml-lt-xl-5,
    .mx-lt-xl-5 {
      margin-left: 3rem !important;
    }

    .p-lt-xl-0 {
      padding: 0 !important;
    }

    .pt-lt-xl-0,
    .py-lt-xl-0 {
      padding-top: 0 !important;
    }

    .pr-lt-xl-0,
    .px-lt-xl-0 {
      padding-right: 0 !important;
    }

    .pb-lt-xl-0,
    .py-lt-xl-0 {
      padding-bottom: 0 !important;
    }

    .pl-lt-xl-0,
    .px-lt-xl-0 {
      padding-left: 0 !important;
    }

    .p-lt-xl-1 {
      padding: 0.25rem !important;
    }

    .pt-lt-xl-1,
    .py-lt-xl-1 {
      padding-top: 0.25rem !important;
    }

    .pr-lt-xl-1,
    .px-lt-xl-1 {
      padding-right: 0.25rem !important;
    }

    .pb-lt-xl-1,
    .py-lt-xl-1 {
      padding-bottom: 0.25rem !important;
    }

    .pl-lt-xl-1,
    .px-lt-xl-1 {
      padding-left: 0.25rem !important;
    }

    .p-lt-xl-2 {
      padding: 0.5rem !important;
    }

    .pt-lt-xl-2,
    .py-lt-xl-2 {
      padding-top: 0.5rem !important;
    }

    .pr-lt-xl-2,
    .px-lt-xl-2 {
      padding-right: 0.5rem !important;
    }

    .pb-lt-xl-2,
    .py-lt-xl-2 {
      padding-bottom: 0.5rem !important;
    }

    .pl-lt-xl-2,
    .px-lt-xl-2 {
      padding-left: 0.5rem !important;
    }

    .p-lt-xl-3 {
      padding: 1rem !important;
    }

    .pt-lt-xl-3,
    .py-lt-xl-3 {
      padding-top: 1rem !important;
    }

    .pr-lt-xl-3,
    .px-lt-xl-3 {
      padding-right: 1rem !important;
    }

    .pb-lt-xl-3,
    .py-lt-xl-3 {
      padding-bottom: 1rem !important;
    }

    .pl-lt-xl-3,
    .px-lt-xl-3 {
      padding-left: 1rem !important;
    }

    .p-lt-xl-4 {
      padding: 1.5rem !important;
    }

    .pt-lt-xl-4,
    .py-lt-xl-4 {
      padding-top: 1.5rem !important;
    }

    .pr-lt-xl-4,
    .px-lt-xl-4 {
      padding-right: 1.5rem !important;
    }

    .pb-lt-xl-4,
    .py-lt-xl-4 {
      padding-bottom: 1.5rem !important;
    }

    .pl-lt-xl-4,
    .px-lt-xl-4 {
      padding-left: 1.5rem !important;
    }

    .p-lt-xl-5 {
      padding: 3rem !important;
    }

    .pt-lt-xl-5,
    .py-lt-xl-5 {
      padding-top: 3rem !important;
    }

    .pr-lt-xl-5,
    .px-lt-xl-5 {
      padding-right: 3rem !important;
    }

    .pb-lt-xl-5,
    .py-lt-xl-5 {
      padding-bottom: 3rem !important;
    }

    .pl-lt-xl-5,
    .px-lt-xl-5 {
      padding-left: 3rem !important;
    }

    .m-lt-xl-n1 {
      margin: -0.25rem !important;
    }

    .mt-lt-xl-n1,
    .my-lt-xl-n1 {
      margin-top: -0.25rem !important;
    }

    .mr-lt-xl-n1,
    .mx-lt-xl-n1 {
      margin-right: -0.25rem !important;
    }

    .mb-lt-xl-n1,
    .my-lt-xl-n1 {
      margin-bottom: -0.25rem !important;
    }

    .ml-lt-xl-n1,
    .mx-lt-xl-n1 {
      margin-left: -0.25rem !important;
    }

    .m-lt-xl-n2 {
      margin: -0.5rem !important;
    }

    .mt-lt-xl-n2,
    .my-lt-xl-n2 {
      margin-top: -0.5rem !important;
    }

    .mr-lt-xl-n2,
    .mx-lt-xl-n2 {
      margin-right: -0.5rem !important;
    }

    .mb-lt-xl-n2,
    .my-lt-xl-n2 {
      margin-bottom: -0.5rem !important;
    }

    .ml-lt-xl-n2,
    .mx-lt-xl-n2 {
      margin-left: -0.5rem !important;
    }

    .m-lt-xl-n3 {
      margin: -1rem !important;
    }

    .mt-lt-xl-n3,
    .my-lt-xl-n3 {
      margin-top: -1rem !important;
    }

    .mr-lt-xl-n3,
    .mx-lt-xl-n3 {
      margin-right: -1rem !important;
    }

    .mb-lt-xl-n3,
    .my-lt-xl-n3 {
      margin-bottom: -1rem !important;
    }

    .ml-lt-xl-n3,
    .mx-lt-xl-n3 {
      margin-left: -1rem !important;
    }

    .m-lt-xl-n4 {
      margin: -1.5rem !important;
    }

    .mt-lt-xl-n4,
    .my-lt-xl-n4 {
      margin-top: -1.5rem !important;
    }

    .mr-lt-xl-n4,
    .mx-lt-xl-n4 {
      margin-right: -1.5rem !important;
    }

    .mb-lt-xl-n4,
    .my-lt-xl-n4 {
      margin-bottom: -1.5rem !important;
    }

    .ml-lt-xl-n4,
    .mx-lt-xl-n4 {
      margin-left: -1.5rem !important;
    }

    .m-lt-xl-n5 {
      margin: -3rem !important;
    }

    .mt-lt-xl-n5,
    .my-lt-xl-n5 {
      margin-top: -3rem !important;
    }

    .mr-lt-xl-n5,
    .mx-lt-xl-n5 {
      margin-right: -3rem !important;
    }

    .mb-lt-xl-n5,
    .my-lt-xl-n5 {
      margin-bottom: -3rem !important;
    }

    .ml-lt-xl-n5,
    .mx-lt-xl-n5 {
      margin-left: -3rem !important;
    }

    .m-lt-xl-auto {
      margin: auto !important;
    }

    .mt-lt-xl-auto,
    .my-lt-xl-auto {
      margin-top: auto !important;
    }

    .mr-lt-xl-auto,
    .mx-lt-xl-auto {
      margin-right: auto !important;
    }

    .mb-lt-xl-auto,
    .my-lt-xl-auto {
      margin-bottom: auto !important;
    }

    .ml-lt-xl-auto,
    .mx-lt-xl-auto {
      margin-left: auto !important;
    }
  }

}
