@use "sass:color";
@use "@angular/material" as mat;
@use "./variables" as gvariables;
@use "./functions" as gfunctions;

// ======= Response Breakpoints (based on Angular Flex) ============
// see node_modules/@angular/flex-layout/_mq.scss

// Non-overlapping Material Design breakpoints
// @type map
$breakpoints: (
  xs: (
    begin: 0,
    end: gfunctions.pixel-to-vmin(599.9px),
  ),
  sm: (
    begin: gfunctions.pixel-to-vmin(600px),
    end: gfunctions.pixel-to-vmin(959.9px),
  ),
  md: (
    begin: gfunctions.pixel-to-vmin(960px),
    end: gfunctions.pixel-to-vmin(1279.9px),
  ),
  lg: (
    begin: gfunctions.pixel-to-vmin(1280px),
    end: gfunctions.pixel-to-vmin(1919.9px),
  ),
  xl: (
    begin: gfunctions.pixel-to-vmin(1920px),
    end: gfunctions.pixel-to-vmin(4999.99px),
  ),
) !default;

// Overlapping breakpoints that are greater than defined
// Material Design breakpoints
// @type map
$overlapping-gt: (
  gt-xs: gfunctions.pixel-to-vmin(600px),
  gt-sm: gfunctions.pixel-to-vmin(960px),
  gt-md: gfunctions.pixel-to-vmin(1280px),
  gt-lg: gfunctions.pixel-to-vmin(1920px),
) !default;

// Overlapping breakpoints that are less than defined
// Material Design breakpoints
// @type map
$overlapping-lt: (
  lt-sm: gfunctions.pixel-to-vmin(599.9px),
  lt-md: gfunctions.pixel-to-vmin(959.9px),
  lt-lg: gfunctions.pixel-to-vmin(1279.9px),
  lt-xl: gfunctions.pixel-to-vmin(1919.9px),
) !default;

@mixin layout-bp($bp) {
  @if map-has-key($breakpoints, $bp) {
    $min: map-get(map-get($breakpoints, $bp), begin);
    $max: map-get(map-get($breakpoints, $bp), end);
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if map-has-key($overlapping-gt, $bp) {
    $min: map-get($overlapping-gt, $bp);
    @media (min-width: $min) {
      @content;
    }
  } @else if map-has-key($overlapping-lt, $bp) {
    $max: map-get($overlapping-lt, $bp);
    @media (max-width: $max) {
      @content;
    }
  }
}
// ==== end Responsive Breakpoints ========================

@mixin custom-css-variables($is-dark-mode) {
  --portal2-custom-bg: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FAFAFA)};
  --portal2-custom-label: #{gfunctions.invert-for-dark-mode($is-dark-mode, #546E7A)};
  --portal2-custom-background-color:  #{gfunctions.invert-for-dark-mode($is-dark-mode, #F5F5F5)};
  --portal2-sg-white: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};

  // from style guides
  --portal2-sg-blue-grey: #{gfunctions.invert-for-dark-mode($is-dark-mode, #607D8B)};
  --portal2-sg-blue-grey-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #ECEFF1)};
  --portal2-sg-blue-grey-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #CFD8CD)};
  --portal2-sg-blue-grey-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #B0BEC5)};
  --portal2-sg-blue-grey-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #90A4AE)};
  --portal2-sg-blue-grey-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #78909C)};
  --portal2-sg-blue-grey-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #546E7A)};
  --portal2-sg-blue-grey-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #455A64)};
  --portal2-sg-blue-grey-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #37474F)};
  --portal2-sg-blue-grey-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #263238)};
  --portal2-sg-blue-grey-darken-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #79809C)};
  --portal2-sg-blue-grey-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-blue-grey-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-blue-grey-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-blue-grey-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-blue-grey-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-blue-grey-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-blue-grey-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-blue-grey-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-blue-grey-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};

  --portal2-sg-brown: #{gfunctions.invert-for-dark-mode($is-dark-mode, #795548)};
  --portal2-sg-brown-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #EFEBE9)};
  --portal2-sg-brown-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #D7CCC8)};
  --portal2-sg-brown-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #BCAAA4)};
  --portal2-sg-brown-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #A1887F)};
  --portal2-sg-brown-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #8D6E63)};
  --portal2-sg-brown-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #6D4C41)};
  --portal2-sg-brown-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #5D4037)};
  --portal2-sg-brown-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #4E342E)};
  --portal2-sg-brown-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #3E2723)};
  --portal2-sg-brown-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-brown-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-brown-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-brown-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-brown-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-brown-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-brown-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-brown-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-brown-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};

  --portal2-sg-grey: #{gfunctions.invert-for-dark-mode($is-dark-mode, #9E9E9E)};
  --portal2-sg-grey-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FAFAFA)};
  --portal2-sg-grey-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #F5F5F5)};
  --portal2-sg-grey-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #EEEEEE)};
  --portal2-sg-grey-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #E0E0E0)};
  --portal2-sg-grey-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #BDBDBD)};
  --portal2-sg-grey-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #757575)};
  --portal2-sg-grey-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #616161)};
  --portal2-sg-grey-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #424242)};
  --portal2-sg-grey-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #212121)};
  --portal2-sg-grey-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-grey-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-grey-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-grey-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-grey-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-grey-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-grey-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-grey-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-grey-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};

  --portal2-sg-red: #{gfunctions.invert-for-dark-mode($is-dark-mode, #F44336)};
  --portal2-sg-red-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFEBEE)};
  --portal2-sg-red-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFCDD2)};
  --portal2-sg-red-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #EF9A9A)};
  --portal2-sg-red-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #E57373)};
  --portal2-sg-red-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #EF5350)};
  --portal2-sg-red-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #E53935)};
  --portal2-sg-red-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #D32F2F)};
  --portal2-sg-red-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #C62828)};
  --portal2-sg-red-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #B71C1C)};
  --portal2-sg-red-accent-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FF8A80)};
  --portal2-sg-red-accent-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FF5252)};
  --portal2-sg-red-accent-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FF1744)};
  --portal2-sg-red-accent-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #D50000)};
  --portal2-sg-red-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-red-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-red-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-red-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #E54234)};
  --portal2-sg-red-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-red-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-red-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-red-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-red-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};

  --portal2-sg-pink: #{gfunctions.invert-for-dark-mode($is-dark-mode, #E91E63)};
  --portal2-sg-pink-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FCE4EC)};
  --portal2-sg-pink-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #F8BBD0)};
  --portal2-sg-pink-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #F48FB1)};
  --portal2-sg-pink-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #F06292)};
  --portal2-sg-pink-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #EC407A)};
  --portal2-sg-pink-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #D81B60)};
  --portal2-sg-pink-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #C2185B)};
  --portal2-sg-pink-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #AD1457)};
  --portal2-sg-pink-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #880E4F)};
  --portal2-sg-pink-accent-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FF80AB)};
  --portal2-sg-pink-accent-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FF4081)};
  --portal2-sg-pink-accent-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #F50057)};
  --portal2-sg-pink-accent-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #C51162)};
  --portal2-sg-pink-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-pink-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-pink-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-pink-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-pink-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-pink-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-pink-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-pink-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-pink-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};

  --portal2-sg-purple: #{gfunctions.invert-for-dark-mode($is-dark-mode, #9C27B0)};
  --portal2-sg-purple-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #F3E5F5)};
  --portal2-sg-purple-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #E1BEE7)};
  --portal2-sg-purple-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #CE93D8)};
  --portal2-sg-purple-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #BA68C8)};
  --portal2-sg-purple-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #AB47BC)};
  --portal2-sg-purple-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #8E24AA)};
  --portal2-sg-purple-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #7B1FA2)};
  --portal2-sg-purple-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #6A1B9A)};
  --portal2-sg-purple-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #4A148C)};
  --portal2-sg-purple-accent-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #EA80FC)};
  --portal2-sg-purple-accent-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #E040FB)};
  --portal2-sg-purple-accent-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #D500F9)};
  --portal2-sg-purple-accent-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #AA00FF)};
  --portal2-sg-purple-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-purple-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-purple-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-purple-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-purple-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-purple-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-purple-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-purple-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-purple-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};

  --portal2-sg-deep-purple: #{gfunctions.invert-for-dark-mode($is-dark-mode, #673AB7)};
  --portal2-sg-deep-purple-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #EDE7F6)};
  --portal2-sg-deep-purple-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #D1C4E9)};
  --portal2-sg-deep-purple-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #B39DDB)};
  --portal2-sg-deep-purple-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #9575CD)};
  --portal2-sg-deep-purple-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #7E57C2)};
  --portal2-sg-deep-purple-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #5E35B1)};
  --portal2-sg-deep-purple-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #512DA8)};
  --portal2-sg-deep-purple-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #4527A0)};
  --portal2-sg-deep-purple-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #311B92)};
  --portal2-sg-deep-purple-accent-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #B388FF)};
  --portal2-sg-deep-purple-accent-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #7C4DFF)};
  --portal2-sg-deep-purple-accent-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #651FFF)};
  --portal2-sg-deep-purple-accent-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #6200EA)};
  --portal2-sg-deep-purple-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-deep-purple-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-deep-purple-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-deep-purple-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-deep-purple-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-deep-purple-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-deep-purple-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-deep-purple-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-deep-purple-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};

  --portal2-sg-indigo: #{gfunctions.invert-for-dark-mode($is-dark-mode, #3F51B5)};
  --portal2-sg-indigo-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #E8EAF6)};
  --portal2-sg-indigo-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #C5CAE9)};
  --portal2-sg-indigo-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #9FA8DA)};
  --portal2-sg-indigo-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #7986CB)};
  --portal2-sg-indigo-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #5C6BC0)};
  --portal2-sg-indigo-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #3949AB)};
  --portal2-sg-indigo-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #303F9F)};
  --portal2-sg-indigo-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #283593)};
  --portal2-sg-indigo-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #1A237E)};
  --portal2-sg-indigo-accent-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #8C9EFF)};
  --portal2-sg-indigo-accent-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #536DFE)};
  --portal2-sg-indigo-accent-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #3D5AFE)};
  --portal2-sg-indigo-accent-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #304FFE)};
  --portal2-sg-indigo-accent-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #0038FF)};
  --portal2-sg-indigo-accent-6: #{gfunctions.invert-for-dark-mode($is-dark-mode, #192851)};
  --portal2-sg-indigo-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-indigo-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-indigo-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-indigo-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-indigo-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-indigo-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-indigo-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-indigo-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-indigo-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};


  --portal2-sg-blue: #{gfunctions.invert-for-dark-mode($is-dark-mode, #2196F3)};
  --portal2-sg-blue-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #E3F2FD)};
  --portal2-sg-blue-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #BBDEFB)};
  --portal2-sg-blue-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #90CAF9)};
  --portal2-sg-blue-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #64B5F6)};
  --portal2-sg-blue-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #42A5F5)};
  --portal2-sg-blue-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #1E88E5)};
  --portal2-sg-blue-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #1976D2)};
  --portal2-sg-blue-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #1565C0)};
  --portal2-sg-blue-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #0D47A1)};
  --portal2-sg-blue-accent-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #82B1FF)};
  --portal2-sg-blue-accent-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #448AFF)};
  --portal2-sg-blue-accent-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #2979FF)};
  --portal2-sg-blue-accent-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #2962FF)};
  --portal2-sg-blue-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-blue-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-blue-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-blue-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-blue-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-blue-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-blue-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-blue-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-blue-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};

  --portal2-sg-light-blue: #{gfunctions.invert-for-dark-mode($is-dark-mode, #03A9F4)};
  --portal2-sg-light-blue-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #E1F5FE)};
  --portal2-sg-light-blue-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #B3E5FC)};
  --portal2-sg-light-blue-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #81D4FA)};
  --portal2-sg-light-blue-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #4FC3F7)};
  --portal2-sg-light-blue-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #29B6F6)};
  --portal2-sg-light-blue-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #039BE5)};
  --portal2-sg-light-blue-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #0288D1)};
  --portal2-sg-light-blue-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #0277BD)};
  --portal2-sg-light-blue-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #01579B)};
  --portal2-sg-light-blue-accent-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #80D8FF)};
  --portal2-sg-light-blue-accent-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #40C4FF)};
  --portal2-sg-light-blue-accent-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #00B0FF)};
  --portal2-sg-light-blue-accent-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #0091EA)};
  --portal2-sg-light-blue-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #CFD8DC)};
  --portal2-sg-light-blue-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-light-blue-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-light-blue-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-light-blue-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-light-blue-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-light-blue-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-light-blue-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-light-blue-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};

  --portal2-sg-cyan: #{gfunctions.invert-for-dark-mode($is-dark-mode, #00BCD4)};
  --portal2-sg-cyan-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #E0F7FA)};
  --portal2-sg-cyan-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #B2EBF2)};
  --portal2-sg-cyan-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #80DEEA)};
  --portal2-sg-cyan-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #4DD0E1)};
  --portal2-sg-cyan-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #26C6DA)};
  --portal2-sg-cyan-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #00ACC1)};
  --portal2-sg-cyan-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #0097A7)};
  --portal2-sg-cyan-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #00838F)};
  --portal2-sg-cyan-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #006064)};
  --portal2-sg-cyan-accent-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #84FFFF)};
  --portal2-sg-cyan-accent-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #18FFFF)};
  --portal2-sg-cyan-accent-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #00E5FF)};
  --portal2-sg-cyan-accent-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #00B8D4)};
  --portal2-sg-cyan-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-cyan-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-cyan-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-cyan-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-cyan-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-cyan-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-cyan-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-cyan-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-cyan-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};


  --portal2-sg-teal: #{gfunctions.invert-for-dark-mode($is-dark-mode, #009688)};
  --portal2-sg-teal-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #E0F2F1)};
  --portal2-sg-teal-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #B2DFDB)};
  --portal2-sg-teal-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #80CBC4)};
  --portal2-sg-teal-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #4DB6AC)};
  --portal2-sg-teal-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #26A69A)};
  --portal2-sg-teal-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #00897B)};
  --portal2-sg-teal-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #00796B)};
  --portal2-sg-teal-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #00695C)};
  --portal2-sg-teal-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #004D40)};
  --portal2-sg-teal-accent-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #A7FFEB)};
  --portal2-sg-teal-accent-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #64FFDA)};
  --portal2-sg-teal-accent-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #1DE9B6)};
  --portal2-sg-teal-accent-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #00BFA5)};
  --portal2-sg-teal-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-teal-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-teal-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-teal-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-teal-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-teal-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-teal-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-teal-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-teal-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};

  --portal2-sg-green: #{gfunctions.invert-for-dark-mode($is-dark-mode, #4CAF50)};
  --portal2-sg-green-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #E8F5E9)};
  --portal2-sg-green-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #C8E6C9)};
  --portal2-sg-green-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #A5D6A7)};
  --portal2-sg-green-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #81C784)};
  --portal2-sg-green-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #66BB6A)};
  --portal2-sg-green-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #43A047)};
  --portal2-sg-green-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #388E3C)};
  --portal2-sg-green-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #2E7D32)};
  --portal2-sg-green-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #1B5E20)};
  --portal2-sg-green-accent-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #B9F6CA)};
  --portal2-sg-green-accent-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #69F0AE)};
  --portal2-sg-green-accent-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #00E676)};
  --portal2-sg-green-accent-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #00C853)};
  --portal2-sg-green-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-green-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-green-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-green-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-green-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-green-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-green-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-green-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-green-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};


  --portal2-sg-light-green: #{gfunctions.invert-for-dark-mode($is-dark-mode, #8BC34A)};
  --portal2-sg-light-green-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #F1F8E9)};
  --portal2-sg-light-green-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #DCEDC8)};
  --portal2-sg-light-green-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #C5E1A5)};
  --portal2-sg-light-green-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #AED581)};
  --portal2-sg-light-green-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #9CCC65)};
  --portal2-sg-light-green-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #7CB342)};
  --portal2-sg-light-green-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #689F38)};
  --portal2-sg-light-green-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #558B2F)};
  --portal2-sg-light-green-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #33691E)};
  --portal2-sg-light-green-darken-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #008000)};
  --portal2-sg-light-green-accent-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #CCFF90)};
  --portal2-sg-light-green-accent-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #B2FF59)};
  --portal2-sg-light-green-accent-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #76FF03)};
  --portal2-sg-light-green-accent-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #64DD17)};
  --portal2-sg-light-green-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-light-green-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-light-green-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-light-green-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-light-green-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-light-green-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-light-green-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-light-green-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-light-green-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-light-green-darken-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};

  --portal2-sg-lime: #{gfunctions.invert-for-dark-mode($is-dark-mode, #CDDC39)};
  --portal2-sg-lime-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #F9FBE7)};
  --portal2-sg-lime-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #F0F4C3)};
  --portal2-sg-lime-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #E6EE9C)};
  --portal2-sg-lime-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #DCE775)};
  --portal2-sg-lime-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #D4E157)};
  --portal2-sg-lime-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #C0CA33)};
  --portal2-sg-lime-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #AFB42B)};
  --portal2-sg-lime-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #9E9D24)};
  --portal2-sg-lime-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #827717)};
  --portal2-sg-lime-accent-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #F4FF81)};
  --portal2-sg-lime-accent-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #EEFF41)};
  --portal2-sg-lime-accent-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #C6FF00)};
  --portal2-sg-lime-accent-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #AEEA00)};
  --portal2-sg-lime-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-lime-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-lime-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-lime-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-lime-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-lime-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-lime-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-lime-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-lime-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};

  --portal2-sg-yellow: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFEB3B)};
  --portal2-sg-yellow-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFDE7)};
  --portal2-sg-yellow-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFF9C4)};
  --portal2-sg-yellow-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFF59D)};
  --portal2-sg-yellow-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFF176)};
  --portal2-sg-yellow-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFEE58)};
  --portal2-sg-yellow-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FDD835)};
  --portal2-sg-yellow-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FBC02D)};
  --portal2-sg-yellow-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #F9A825)};
  --portal2-sg-yellow-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #F57F17)};
  --portal2-sg-yellow-accent-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFF8D)};
  --portal2-sg-yellow-accent-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFF00)};
  --portal2-sg-yellow-accent-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFEA00)};
  --portal2-sg-yellow-accent-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFD600)};
  --portal2-sg-yellow-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-yellow-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-yellow-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-yellow-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-yellow-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-yellow-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-yellow-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-yellow-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-yellow-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};

  --portal2-sg-amber: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFC107)};
  --portal2-sg-amber-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFF8E1)};
  --portal2-sg-amber-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFECB3)};
  --portal2-sg-amber-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFE082)};
  --portal2-sg-amber-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFD54F)};
  --portal2-sg-amber-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFCA28)};
  --portal2-sg-amber-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFB300)};
  --portal2-sg-amber-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFA000)};
  --portal2-sg-amber-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FF8F00)};
  --portal2-sg-amber-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FF6F00)};
  --portal2-sg-amber-accent-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFE57F)};
  --portal2-sg-amber-accent-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFD740)};
  --portal2-sg-amber-accent-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFC400)};
  --portal2-sg-amber-accent-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFAB00)};
  --portal2-sg-amber-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-amber-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-amber-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-amber-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-amber-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-amber-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-amber-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-amber-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-amber-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};

  --portal2-sg-orange: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FF9800)};
  --portal2-sg-orange-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFF3E0)};
  --portal2-sg-orange-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFE0B2)};
  --portal2-sg-orange-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFCC80)};
  --portal2-sg-orange-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFB74D)};
  --portal2-sg-orange-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFA726)};
  --portal2-sg-orange-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FB8C00)};
  --portal2-sg-orange-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #F57C00)};
  --portal2-sg-orange-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #EF6C00)};
  --portal2-sg-orange-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #E65100)};
  --portal2-sg-orange-accent-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFD180)};
  --portal2-sg-orange-accent-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFAB40)};
  --portal2-sg-orange-accent-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FF9100)};
  --portal2-sg-orange-accent-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FF6D00)};
  --portal2-sg-orange-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-orange-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-orange-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-orange-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-orange-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-orange-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-orange-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-orange-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-orange-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};


  --portal2-sg-deep-orange: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FF5722)};
  --portal2-sg-deep-orange-lighten-5: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FBE9E7)};
  --portal2-sg-deep-orange-lighten-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFCCBC)};
  --portal2-sg-deep-orange-lighten-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFAB91)};
  --portal2-sg-deep-orange-lighten-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FF8A65)};
  --portal2-sg-deep-orange-lighten-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FF7043)};
  --portal2-sg-deep-orange-darken-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #F4511E)};
  --portal2-sg-deep-orange-darken-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #E64A19)};
  --portal2-sg-deep-orange-darken-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #D84315)};
  --portal2-sg-deep-orange-darken-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #BF360C)};
  --portal2-sg-deep-orange-accent-1: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FF9E80)};
  --portal2-sg-deep-orange-accent-2: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FF6E40)};
  --portal2-sg-deep-orange-accent-3: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FF3D00)};
  --portal2-sg-deep-orange-accent-4: #{gfunctions.invert-for-dark-mode($is-dark-mode, #DD2C00)};
  --portal2-sg-deep-orange-lighten-5-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-deep-orange-lighten-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-deep-orange-lighten-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-deep-orange-lighten-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-deep-orange-lighten-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #000000)};
  --portal2-sg-deep-orange-darken-1-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-deep-orange-darken-2-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-deep-orange-darken-3-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
  --portal2-sg-deep-orange-darken-4-contrast: #{gfunctions.invert-for-dark-mode($is-dark-mode, #FFFFFF)};
}

$blue-filter: invert(45%) sepia(92%) saturate(7212%) hue-rotate(245deg) brightness(100%) contrast(103%);
.filter-green {
  filter: $blue-filter;
  margin-right: gfunctions.pixel-to-vmin(10px);
}



@mixin mat-theme-to-css-variables($theme, $prefix:'') {
  $primary-palatte: map-get($theme, primary);
  $accent-palatte: map-get($theme, accent);
  $warn-palatte: map-get($theme, warn);
  $foreground-palatte: map-get($theme, foreground);
  $background-palatte: map-get($theme, background);

  @if $prefix == '' {
    @include mat-palatte-to-css-variables($primary-palatte, 'portal2-primary');
    @include mat-palatte-to-css-variables($accent-palatte, 'portal2-accent');
    @include mat-palatte-to-css-variables($warn-palatte, 'portal2-warn');
    @include mat-palatte-to-css-variables($foreground-palatte, 'portal2-foreground');
    @include mat-palatte-to-css-variables($background-palatte, 'portal2-background');
  }@else {
    @include mat-palatte-to-css-variables($primary-palatte, 'portal2-#{$prefix}-primary');
    @include mat-palatte-to-css-variables($accent-palatte, 'portal2-#{$prefix}-accent');
    @include mat-palatte-to-css-variables($warn-palatte, 'portal2-#{$prefix}-warn');
    @include mat-palatte-to-css-variables($foreground-palatte, 'portal2-#{$prefix}-foreground');
    @include mat-palatte-to-css-variables($background-palatte, 'portal2-#{$prefix}-background');
  }
}

@mixin mat-palatte-to-css-variables($palatte, $prefix) {
  @each $name, $value in $palatte {
    @if $name == 'contrast' {
      @each $cName, $cValue in $value {
        --#{$prefix}-#{$cName}: #{$cValue};
      }
    }@else {
      --#{$prefix}-#{$name}: #{$value};
    }
  }
}

@mixin global-customisation() {
  // generic global
  .font-weight-500 {
    font-weight: 500;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .pointer-event-mat-tooltip {
    pointer-events: auto;
    &:hover {
      border-radius: 0 !important;
      padding: 0px !important;
      background: transparent !important;
    }
  }
  a {
    color: var(--portal2-primary-default) !important;
    text-decoration: none;
  }
  .hidden {
    display: none !important;
    visibility: hidden !important;
  }
  .word-wrap {
    word-wrap: break-word;
    white-space: initial;
  }
  .hover-scroll {
    &:hover {
      overflow-y: scroll;
    }
  }
  .height-100 {
    height: 100%;
  }
  .width-100 {
    width: 100%;
  }
  .width-75 {
    width: 75%;
  }
  .width-66 {
    width: 66%;
  }
  .width-50 {
    width: 50%;
  }
  .width-33 {
    width: 33%;
  }
  .width-25 {
    width: 25%;
  }

  .text-align-center {
    text-align: center;
  }

  .float-right {
    float: right;
  }

  .word-wrap {
    word-wrap: break-word;
  }

  .separator {
    margin-top: gfunctions.pixel-to-vmin(20px);
    margin-bottom: gfunctions.pixel-to-vmin(20px);
  }
  .separator-top {
    margin-top: gfunctions.pixel-to-vmin(20px);
  }
  .separator-bottom {
    margin-bottom: gfunctions.pixel-to-vmin(20px);
    color: var(--portal2-sg-blue-grey-darken-3);
  }
  .narrow-separator {
    margin-top: gfunctions.pixel-to-vmin(10px);
    margin-bottom: gfunctions.pixel-to-vmin(10px);
  }

  .background-light {
    background-color: var(--portal2-background-background);
  }
  .color-primary {
    color: var(--portal2-primary-default);
  }
  .color-warn {
    color: var(--portal2-warn-default);
  }
  .color-info {
    color: var(--portal2-sg-yellow-darken-4);
  }
  // error-tailor
  control-error {
    .control-error {
      color: var(--portal2-warn-default);
      font-size: gfunctions.pixel-to-vmin(14px);
    }
    & :hover {
      color: var(--portal2-warn-default)!important;
    }
  }

  // note: not sure what this is doing
  .alternate-hover {
    padding: gfunctions.pixel-to-vmin(8px) gfunctions.pixel-to-vmin(10px);
    &:hover {
      border-radius: 50%;
      padding: gfunctions.pixel-to-vmin(8px) gfunctions.pixel-to-vmin(10px);
      background: rgba(255, 255, 255, 0.1);
      transition: 0.2s;
    }
  }

  // progress spinner dialog
  .progress-spinner-dialog {
    .mat-mdc-dialog-container {
      box-shadow: none !important;
      background: rgba(0, 0, 0, 0.0) !important;
      .mdc-dialog__surface{
        background: rgba(0, 0, 0, 0.0) !important;
      }
      .mdc-dialog__container {
        .mat-mdc-dialog-surface {
          .mat-mdc-dialog-content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  // hot-taost message popup
  .hot-toast-bar-base-container {
    .hot-toast-bar-base {
      background-color: var(--mat-app-background-color)!important;
      max-width: none !important;
      .hot-toast-close-btn{
        align-self: center;
        margin-top: 0;
      }
    }
  }

  // custom hot-toast popup
  .hot-toast-info-base {
    --hot-toast-close-btn-background-image:none;
    border: gfunctions.pixel-to-vmin(2px) solid var(--portal2-primary-default);
    border-radius: 0 !important;
    $color: RGBA(var(--portal2-primary-default), 0.12);
    // box-shadow: inset 8px 0px 0px rgba(0, 56, 255, 0.12) !important;
    .hot-toast-close-btn {
      color: var(--portal2-primary-default);
    }
    .hot-toast-info {
      color: var(--portal2-primary-default);
      .mdi {
        // font-size: gfunctions.pixel-to-vmin(30px);
        font-size: x-large !important;
        font-weight: bolder;
        color:var(--portal2-primary-default);
      }
      .message {
        color: var(--portal2-sg-blue-grey-darken-4);
        font-weight: normal;
        font-size: pixel-to-vmin(16px);
      }
      .title {
        font-weight: 500;
        font-size: pixel-to-vmin(16px);
        line-height: pixel-to-vmin(19px);
        color: var(--portal2-sg-blue-grey-darken-4);
      }
    }
  }
  .hot-toast-success-notification {
    color: var(--portal2-sg-blue-grey-lighten-1);
    .mdi {
      // font-size: gfunctions.pixel-to-vmin(30px);
      font-size: x-large !important;
      font-weight: bolder;
    }
    .message {
      color: var(--portal2-sg-blue-grey-lighten-1);
      font-weight: bold;
      font-size: gfunctions.pixel-to-vmin(14px);
      margin-left: gfunctions.pixel-to-vmin(20px);
      cursor: pointer;
      line-height: gfunctions.pixel-to-vmin(17px);
    }
    .title {
      font-weight: 500;
      font-size: gfunctions.pixel-to-vmin(16px);
      line-height: gfunctions.pixel-to-vmin(19px);
      color: var(--portal2-sg-blue-grey-lighten-4);
    }
  }
  .hot-toast-warn-base {
    --hot-toast-close-btn-background-image: url("/assets/images/cross-warn.png");
    max-width: none !important;
    border: gfunctions.pixel-to-vmin(2px) solid
      var(--portal2-additional-accent-default);
    // box-shadow: inset 8px 0px 0px rgba(252, 104, 3, 0.12) !important;
    border-radius: 0 !important;
    .hot-toast-close-btn {
      color: var(--portal2-sg-orange-darken-1);
    }
    .hot-toast-warn {
      color: var(--portal2-sg-orange-darken-1);
      .mdi {
        // font-size: gfunctions.pixel-to-vmin(30px);
        font-size: x-large !important;
        font-weight: bolder;
      }
      .message {
        color: var(--portal2-sg-blue-grey-darken-4);
        font-weight: normal;
        font-size: pixel-to-vmin(16px);
      }
      .title {
        font-weight: 500;
        font-size: pixel-to-vmin(16px);
        line-height: pixel-to-vmin(19px);
        color: var(--portal2-sg-blue-grey-darken-4);
      }
    }
  }
  .hot-toast-success-base {
    --hot-toast-close-btn-background-image: url("/assets/images/cross-details.png");
    max-width: none !important;
    border: 2px solid var(--portal2-additional-primary-default);
    // box-shadow: inset 8px 0px 0px rgba(0, 128, 0, 0.12) !important;
    border-radius: 0 !important;
    .hot-toast-close-btn {
      color: var(--portal2-sg-green-darken-4);
    }
    .hot-toast-success {
      color: var(--portal2-sg-green-darken-4);
      .mdi {
        // font-size: gfunctions.pixel-to-vmin(30px);
        font-size: x-large !important;
        font-weight: bolder;
      }
      .message {
        color: var(--portal2-sg-blue-grey-darken-4);
        font-weight: normal;
        font-size: pixel-to-vmin(16px);
      }
      .title {
        font-weight: 500;
        font-size: pixel-to-vmin(16px);
        line-height: pixel-to-vmin(19px);
        color: var(--portal2-sg-blue-grey-darken-4);
      }
    }
  }
  .hot-toast-error-base {
    --hot-toast-close-btn-background-image: url("/assets/images/cross-danger.png");
    max-width: none !important;
    border: gfunctions.pixel-to-vmin(2px) solid var(--portal2-warn-default);
    // box-shadow: inset 8px 0px 0px rgba(229, 66, 52, 0.12) !important;
    border-radius: 0 !important;
    .hot-toast-close-btn {
      color: var(--portal2-warn-default);
    }
    .hot-toast-error {
      color: var(--portal2-warn-default);
      .mdi {
        // font-size: gfunctions.pixel-to-vmin(30px);
        font-size: x-large !important;
        font-weight: bolder;
      }
      .message {
        color: var(--portal2-sg-blue-grey-darken-4);
        font-weight: normal;
        font-size: pixel-to-vmin(16px);
      }
      .title {
        font-weight: 500;
        font-size: pixel-to-vmin(16px);
        line-height: pixel-to-vmin(19px);
        color: var(--portal2-sg-blue-grey-darken-4);
      }
    }
  }

  // ellipsis
  .ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  // NOTE: not sure, date styling ??
  .date-text {
    color: var(--portal2-sg-blue-grey-lighten-1);
    font-size: gfunctions.pixel-to-vmin(12px);
  }


  // mdi icons
  .mdi {
    font-size: gfunctions.pixel-to-vmin(20px);
  }

  // NOTE: not sure where this is used? nav icons and back arrow button ???
  .icon-hover {
    .mdi {
      padding: gfunctions.pixel-to-vmin(10px) gfunctions.pixel-to-vmin(10px);
      &:hover {
        border-radius: 50%;
        padding: gfunctions.pixel-to-vmin(10px) gfunctions.pixel-to-vmin(10px);
        background: var(--portal2-sg-grey-lighten-3);
        transition: 0.2s;
      }
    }
  }


  // NOTE: not sure what this is for
  .icon-shape {
    padding: gfunctions.pixel-to-vmin(12px);
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    i,
    svg {
      font-size: 1.2rem;
    }

    &.icon-lg {
      i,
      svg {
        font-size: 1.625rem;
      }
    }

    &.icon-sm {
      i,
      svg {
        font-size: 0.875rem;
      }
    }

    &.icon-xs {
      i,
      svg {
        font-size: 0.6rem;
      }
    }

    svg {
      width: gfunctions.pixel-to-vmin(30px);
      height: gfunctions.pixel-to-vmin(30px);
    }
  }


  // NOTE: not sure what this is for
  .arrow-left {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    background: transparent;
    color: var(--portal2-sg-blue-grey-darken-4);
    cursor: pointer;
  }



  .content-width {
    justify-self: center;
    width: 100%;
  }


  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  // NOTE: we should not need this, it will affect themeing and light/dark mode
  // .bg-white {
  //   background-color: white;
  // }

  // NOTE: override bootstrap's card style, we need to get rid of this after
  //       we move cards to use <mat-card> from material
  // .card {
  //   // background-color: var(--portal2-sg-white) !important;
  //   background-color: var(--mat-table-background-color) !important;
  // }

  .small-medium-text {
    font-size: gfunctions.pixel-to-vmin(14px);
  }

  // dialog box height
  .max-height-100-vh {
    max-height: 100vh !important;
  }

  .not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: gfunctions.pixel-to-vmin(32px);
    padding: gfunctions.pixel-to-vmin(20px) !important;
    font-size: gfunctions.pixel-to-vmin(24px) !important;
    color: var(--portal2-sg-blue-grey-lighten-1);
  }
}


  // form styles for login-layout and its decendents layouts
@mixin form-styles() {
  ::ng-deep {
    width: 100%;
    .form-field {
      width: 100%;
    }
    .form-button {
      width: 100%;
      .mat-mdc-button-base {
        width: 100%;
        margin-bottom: gfunctions.pixel-to-vmin(20px);
      }
    }
    .form-text {
      margin-bottom: gfunctions.pixel-to-vmin(20px);
    }
  }
}

@mixin stage-and-status-styling() {
  //Draft , Paid
  $color1: var(--portal2-sg-blue-grey-darken-4); // #263238
  $color1-contrast: var( --portal2-sg-grey-lighten-5); // #FAFAFA

  // Under review ,in progress
  $color2: var(--portal2-sg-light-blue-darken-4); // #01579B
  $color2-contrast: var(--portal2-sg-blue-lighten-5); // #E3F2FD

  //Approved, Accepted
  $color3: var(--portal2-sg-green); // #4CAF50
  $color3-contrast: var(--portal2-sg-green-lighten-5); // #E8F5E9

  //Waiting for bank statements:
  $color4: var( --portal2-sg-purple-darken-2); // #7B1FA2
  $color4-contrast: var( --portal2-sg-purple-lighten-5); // #F3E5F5

  //Submit to credit:
  $color5: var(--portal2-sg-brown-lighten-1); // #38D6E63
  $color5-contrast: var( --portal2-sg-brown-lighten-5); // #EFEBE9

  // More info:
  $color6: var(--portal2-sg-blue);
  $color6-contrast: var(--portal2-sg-blue-lighten-5); // #E3F2FD;

  //Credit Approved
  $color7: var(--portal2-sg-white); //#FFFFFF
  $color7-contrast: var(--portal2-sg-green); // #4CAF50

  //Not verified
  $color8: var(--portal2-sg-red-lighten-2-contrast);  // #E54234
  $color8-contrast: var(--portal2-sg-red-lighten-5); // #FFEBEE

  //Pending Payment
  $color9: var(--portal2-sg-white); //#FFFFFF
  $color9-contrast: var(--portal2-sg-green);// #4CAF50

  //Disapproved, Decline, Unpaid, Rejected
  $color10:  var(--portal2-sg-red-lighten-2-contrast); //E54234
  $color10-contrast: var(--portal2-sg-red-lighten-5); //FFEBEE

  //Settled (todo: confirmation, no color code in guidline for settled)
  $color11: var(--portal2-sg-green); // #4CAF50
  $color11-contrast: var(--portal2-sg-green-lighten-5); // #E8F5E9

  //Withdrawn (todo: confirmation, no color code in guidline for withdrawn)
  $color12:  var(--portal2-sg-red-lighten-2-contrast); //E54234
  $color12-contrast: var(--portal2-sg-red-lighten-5); //FFEBEE

  //Declined (todo: confirmation, no color code in guidline for declined)
  $color13:  var(--portal2-sg-red-lighten-2-contrast); //E54234
  $color13-contrast: var(--portal2-sg-red-lighten-5); //FFEBEE

  //Unfundable
  $color14: var(--portal2-sg-blue-grey-lighten-1);
  $color14-contrast: var(--portal2-sg-blue-grey-darken-1-contrast);

  //Pending
  $color15: var(--portal2-sg-yellow-darken-4);
  $color15-contrast: var(--portal2-sg-yellow-lighten-5);

  // failed / decline
  $color16:  var(--portal2-sg-red-lighten-2-contrast); //E54234
  $color16-contrast: var(--portal2-sg-red-lighten-5); //FFEBEE

  // not available
  $color17: var(--portal2-sg-yellow-darken-4);
  $color17-contrast: var(--portal2-sg-yellow-lighten-5);

  // more information
  $color18: var(--portal2-sg-blue);
  $color18-contrast: var(--portal2-sg-blue-lighten-5);

  // credit approved
  $color19: var(--portal2-sg-green-darken-1);
  $color19-contrast: var(--portal2-sg-grey-lighten-4);

  // deal preparation
  $color20: var(--portal2-sg-amber-darken-4);
  $color20-contrast: var(--portal2-sg-amber-lighten-5);

  // settlement
  $color21: var(--portal2-sg-deep-purple-darken-2);
  $color21-contrast: var(--portal2-sg-deep-purple-lighten-5);

  .lead {
    background-color: $color11-contrast;
    color: $color11;
  }

  .lead-border {
    border: gfunctions.pixel-to-vmin(2px) solid $color11!important;
  }

  .submittedtocredit, .created {
    background-color: $color5-contrast;
    color: $color5;
  }

  .submittedtocredit-border {
    border: gfunctions.pixel-to-vmin(2px) solid $color5!important;
  }

  .waitingforbankstatements, .waitforbankstatement, .opened, .ruleset, .requestbankstatements {
    background-color: $color4-contrast;
    color: $color4;
  }

  .waitingforbankstatements-border {
    border: gfunctions.pixel-to-vmin(2px) solid $color4!important;
  }

  .moreinformation, .moreinfo {
    background-color: $color18-contrast;
    color: $color18;
  }

  .moreinformation-border {
    border: gfunctions.pixel-to-vmin(2px) solid $color18!important;
  }

  .creditapproved {
    background-color: $color7-contrast;
    color: $color7;
  }

  .creditapproved-border {
    border: gfunctions.pixel-to-vmin(2px) solid $color7!important;
  }

  .dealpreparation {
    background-color: $color20-contrast;
    color: $color20;
  }

  .dealpreparation-border {
    border: gfunctions.pixel-to-vmin(2px) solid $color20!important;
  }

  .documentation {
    background-color: $color18-contrast;
    color: $color18;
  }

  .documentation-border {
    border: gfunctions.pixel-to-vmin(2px) solid $color18!important;
  }

  .docsout {
    background-color: $color5-contrast;
    color: $color5;
  }

  .docsout-border {
    border: gfunctions.pixel-to-vmin(2px) solid $color5!important;
  }

  .settlementpending {
    background-color: $color19-contrast;
    color: $color19;
  }

  .settlementpending-border {
    border: gfunctions.pixel-to-vmin(2px) solid $color19!important;
  }

  .qa {
    background-color: $color18-contrast;
    color: $color18;
  }

  .qa-border {
    border: gfunctions.pixel-to-vmin(2px) solid $color18!important;
  }

  .settlement, .settled ,.primarycontact{
    background-color: $color21-contrast;
    color: $color21;
  }

  .settlement-border {
    border: gfunctions.pixel-to-vmin(2px) solid $color21!important;
  }

  .closedwon, .closewon, .signed {
    background-color: $color11-contrast;
    color: $color11;
  }

  .closedwon-border {
    border: gfunctions.pixel-to-vmin(2px) solid $color11!important;
  }

  .closedlost, .closelost {
    background-color: $color8-contrast;
    color: $color8;
  }

  .closedlost-border {
    border: gfunctions.pixel-to-vmin(2px) solid $color8!important;
  }

  .settled {
    background-color: $color11-contrast;
    color: $color11;
  }
  .widthdrawn, .closed {
    background-color: $color12-contrast;
    color: $color12;
  }
  .declined, .notsent {
    background-color: $color13-contrast;
    color: $color13;
  }
  .underreview, .commercial, .webservice, .refer {
    background-color: $color2-contrast;
    color: $color2;
  }
  .approved, .accepted {
    background-color: $color3-contrast;
    color: $color3;
  }
  .private {
    background-color: $color5-contrast;
    color: $color5;
  }
  .notverified, .autoresponded {
    background-color: $color8-contrast;
    color: $color8;
  }
  .pendingpayment , .success, .approved, .approve, .startnode {
    background-color: $color9-contrast;
    color: $color9;
  }
  .disapproved, .fail, .rejected, .endnode {
    background-color: $color10-contrast;
    color: $color10;
  }
  .declined {
    background-color: $color10-contrast;
    color: $color10;
  }
  .paid, .draft {
    // border: 1px solid var(--portal2-sg-grey-lighten-2);
    background-color: var(--portal2-sg-grey-lighten-4);
    color: $color1;
  }
  .pending, .sent, .faxpending, .route, .validate {
    background-color: $color15-contrast;
    color: $color15;
  }
  .unpaid {
    background-color: $color10-contrast;
    color: $color10;
  }
  .inprogress{
    background-color: $color2-contrast;
    color: $color2;
  }
  .sold {
    background-color:$color2-contrast ;
    color: var(--portal2-sg-blue);
  }
  .completed {
    border: 1px solid var(--portal2-sg-blue-lighten-4);
    color: var(--portal2-sg-blue);
  }
  .cancelled{
    border: 1px solid var(--portal2-sg-grey-lighten-2);
    color: var(--portal2-sg-blue-grey-lighten-1);
  }
  .compliancereview {
    background-color: $color2-contrast;
    color: $color2;
  }
  .unfundable, .optional {
    color: $color14;
    background-color: $color14-contrast;
  }
  .failed, .decline {
    color: $color16;
    background-color: $color16-contrast;
  }
  .notavailable {
    color: $color17;
    background-color: $color17-contrast;
  }
  .new, .accredited {
    color: $color6;
    border: 1px solid var(--portal2-sg-blue-lighten-4);
  }
  .private-seller {
    color: $color4;
    border: 1px solid var(--portal2-sg-purple-darken-2);
  }
}

@mixin pismo-transaction-styling() {
  //transfer //fees
  $color1: var(--portal2-sg-deep-orange);
  $color1-contrast: var(--portal2-sg-deep-orange-lighten-5);

  //reversals //adjustments
  $color2: var(--portal2-sg-purple);
  $color2-contrast: var(--portal2-sg-purple-lighten-5);

  //bpay
  $color3: #183268;
  $color3-contrast: #E1E4EC;

  //payment //default
  $color4: var(--portal2-sg-light-green-darken-5);
  $color4-contrast: var(--portal2-sg-green-lighten-5);

  //merchant
  $color5: var(--portal2-sg-indigo-accent-5);
  $color5-contrast: var(--portal2-sg-blue-lighten-5);

  //interests
  $color6: var(--portal2-sg-pink);
  $color6-contrast: var(--portal2-sg-pink-lighten-5);

  //voucher
  $color7: var(--portal2-sg-deep-purple-lighten-1);
  $color7-contrast: var(--portal2-sg-deep-purple-lighten-5);

  //taxes
  $color8: var(--portal2-sg-amber-darken-4);
  $color8-contrast: var(--portal2-sg-amber-lighten-5);

  //exchange
  $color9: var(--portal2-sg-purple-accent-4);
  $color9-contrast: var(--portal2-sg-purple-lighten-5);

  //annuity
  $color10: var(--portal2-sg-brown);
  $color10-contrast: var(--portal2-sg-brown-lighten-5);

  .transfer,
  .fees {
    color: $color1;
    background-color: $color1-contrast;
  }

  .reversals,
  .adjustments {
    color: $color2;
    background-color: $color2-contrast;
  }

  .bpay {
    color: $color3;
    background-color: $color3-contrast;
  }

  .payment,
  .default {
    color: $color4;
    background-color: $color4-contrast;
  }

  .merchant {
    color: $color5;
    background-color: $color5-contrast;
  }

  .interests {
    color: $color6;
    background-color: $color6-contrast;
  }

  .voucher {
    color: $color7;
    background-color: $color7-contrast;
  }

  .taxes {
    color: $color8;
    background-color: $color8-contrast;
  }

  .exchange {
    color: $color9;
    background-color: $color9-contrast;
  }

  .annuity {
    color: $color10;
    background-color: $color10-contrast;
  }
}

