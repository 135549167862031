
$base-font-size: 16px;
$apply-pixel-to-em: false;
$apply-pixel-to-rem: true;
$apply-pixel-to-vmin: true;

$grow-dark-primary-text: rgba(#000000, 0.87);
$grow-light-primary-text: #ffffff;
/**
 * NOTE: these css variables are being defined in index.html
 */
$grow-primary-palette: (
  50: var(--grow-primary-palette-50),
  100: var(--grow-primary-palette-100),
  200: var(--grow-primary-palette-200),
  300: var(--grow-primary-palette-300),
  400: var(--grow-primary-palette-400),
  500: var(--grow-primary-palette-500),
  600: var(--grow-primary-palette-600),
  700: var(--grow-primary-palette-700),
  800: var(--grow-primary-palette-800),
  900: var(--grow-primary-palette-900),
  A100: var(--grow-primary-palette-A100),
  A200: var(--grow-primary-palette-A200),
  A400: var(--grow-primary-palette-A400),
  A700: var(--grow-primary-palette-A700),
  contrast: (
    50: var(--grow-primary-palette-contrast-50),
    100: var(--grow-primary-palette-contrast-100),
    200: var(--grow-primary-palette-contrast-200),
    300: var(--grow-primary-palette-contrast-300),
    400: var(--grow-primary-palette-contrast-400),
    500: var(--grow-primary-palette-contrast-500),
    600: var(--grow-primary-palette-contrast-600),
    700: var(--grow-primary-palette-contrast-700),
    800: var(--grow-primary-palette-contrast-800),
    900: var(--grow-primary-palette-contrast-900),
    A100: var(--grow-primary-palette-contrast-A100),
    A200: var(--grow-primary-palette-contrast-A200),
    A400: var(--grow-primary-palette-contrast-A400),
    A700: var(--grow-primary-palette-contrast-A700),
  )
);
$grow-accent-palette: (
  50: var(--grow-accent-palette-50),
  100: var(--grow-accent-palette-100),
  200: var(--grow-accent-palette-200),
  300: var(--grow-accent-palette-300),
  400: var(--grow-accent-palette-400),
  500: var(--grow-accent-palette-500),
  600: var(--grow-accent-palette-600),
  700: var(--grow-accent-palette-700),
  800: var(--grow-accent-palette-800),
  900: var(--grow-accent-palette-900),
  A100: var(--grow-accent-palette-A100),
  A200: var(--grow-accent-palette-A200),
  A400: var(--grow-accent-palette-A400),
  A700: var(--grow-accent-palette-A700),
  contrast: (
    50: var(--grow-accent-palette-contrast-50),
    100: var(--grow-accent-palette-contrast-100),
    200: var(--grow-accent-palette-contrast-200),
    300: var(--grow-accent-palette-contrast-300),
    400: var(--grow-accent-palette-contrast-400),
    500: var(--grow-accent-palette-contrast-500),
    600: var(--grow-accent-palette-contrast-600),
    700: var(--grow-accent-palette-contrast-700),
    800: var(--grow-accent-palette-contrast-800),
    900: var(--grow-accent-palette-contrast-900),
    A100: var(--grow-accent-palette-contrast-A100),
    A200: var(--grow-accent-palette-contrast-A200),
    A400: var(--grow-accent-palette-contrast-A400),
    A700: var(--grow-accent-palette-contrast-A700),
  )
);
$grow-warn-palette: (
  50: var(--grow-warn-palette-50),
  100: var(--grow-warn-palette-100),
  200: var(--grow-warn-palette-200),
  300: var(--grow-warn-palette-300),
  400: var(--grow-warn-palette-400),
  500: var(--grow-warn-palette-500),
  600: var(--grow-warn-palette-600),
  700: var(--grow-warn-palette-700),
  800: var(--grow-warn-palette-800),
  900: var(--grow-warn-palette-900),
  A100: var(--grow-warn-palette-A100),
  A200: var(--grow-warn-palette-A200),
  A400: var(--grow-warn-palette-A400),
  A700: var(--grow-warn-palette-A700),
  contrast: (
    50: var(--grow-warn-palette-contrast-50),
    100: var(--grow-warn-palette-contrast-100),
    200: var(--grow-warn-palette-contrast-200),
    300: var(--grow-warn-palette-contrast-300),
    400: var(--grow-warn-palette-contrast-400),
    500: var(--grow-warn-palette-contrast-500),
    600: var(--grow-warn-palette-contrast-600),
    700: var(--grow-warn-palette-contrast-700),
    800: var(--grow-warn-palette-contrast-800),
    900: var(--grow-warn-palette-contrast-900),
    A100: var(--grow-warn-palette-contrast-A100),
    A200: var(--grow-warn-palette-contrast-A200),
    A400: var(--grow-warn-palette-contrast-A400),
    A700: var(--grow-warn-palette-contrast-A700),
  )
);
$grow-info-palette: (
  50: #e0e7ff,
  100: #b3c3ff,
  200: #809cff,
  300: #4d74ff,
  400: #2656ff,
  500: #0038ff,
  600: #0032ff,
  700: #002bff,
  800: #0024ff,
  900: #0017ff,
  A100: #ffffff,
  A200: #f2f3ff,
  A400: #bfc2ff,
  A700: #a6aaff,
  contrast: (
    50: $grow-dark-primary-text,
    100: $grow-dark-primary-text,
    200: $grow-dark-primary-text,
    300: $grow-dark-primary-text,
    400: $grow-light-primary-text,
    500: $grow-light-primary-text,
    600: $grow-light-primary-text,
    700: $grow-light-primary-text,
    800: $grow-light-primary-text,
    900: $grow-light-primary-text,
    A100: $grow-dark-primary-text,
    A200: $grow-dark-primary-text,
    A400: $grow-dark-primary-text,
    A700: $grow-dark-primary-text,
  )
);

$grow-orange-palette: (
  50: #ffffff,
  100: #fed8bd,
  200: #feb786,
  300: #fd8c3f,
  400: #fc7a21,
  500: #fc6803,
  600: #de5c03,
  700: #c04f02,
  800: #a14302,
  900: #833602,
  A100: #ffffff,
  A200: #ffc299,
  A400: #ff8633,
  A700: #ff771a,
  contrast: (
    50: $grow-dark-primary-text,
    100: $grow-dark-primary-text,
    200: $grow-dark-primary-text,
    300: $grow-dark-primary-text,
    400: $grow-dark-primary-text,
    500: $grow-dark-primary-text,
    600: $grow-dark-primary-text,
    700: $grow-light-primary-text,
    800: $grow-light-primary-text,
    900: $grow-light-primary-text,
    A100: $grow-dark-primary-text,
    A200: $grow-dark-primary-text,
    A400: $grow-dark-primary-text,
    A700: $grow-dark-primary-text,
  )
);


$tag-colors: (
  color1: #01579B,
  color1-contrast: #E3F2FD,

  color2: #263238,
  color2-contrast: #FAFAFA,

  color3: #008000,
  color3-contrast: #E8F5E9,

  color4: #8D6E63,
  color4-contrast: #EFEBE9,

  color5: #7B1FA2,
  color5-contrast: #F3E5F5,

  color6: #FFFFFF,
  color6-contrast: #008000,

  color7: #e54234,
  color7-contrast: #FFFF,
);

